<template>
  <v-container class="pa-10">
    <router-link to="/tests">Back to tests list</router-link>
    <h1 v-if="!$store.state.loading.tests && test && test.uid">
      {{ test.name }}
    </h1>
    <v-btn class="primary--text mt-4" color="accent" @click="saveTest()"
      >Save</v-btn
    >

    <div class="cards" v-if="!$store.state.loading.tests && test && test.uid">
      <div class="left">
        <v-card flat class="pa-5 mr-7 mb-7 my-7" style="width: 400px">
          <v-card-title class="headline"><b>Requirements</b></v-card-title>
          <v-divider class="mb-4 mx-4"></v-divider>

          <v-text-field
            v-model="test.name"
            label="Test name"
            class="px-4"
          ></v-text-field>
          <v-select
            v-model="test.client"
            :items="$store.state.companies"
            :loading="$store.state.loading.companies"
            item-text="name"
            return-object
            label="Client"
            class="px-4"
          ></v-select>
          <v-select
            v-model="test.type"
            :items="typeItems"
            label="Test type"
            class="px-4"
          ></v-select>
          <v-text-field
            v-model="test.invoiced"
            label="Invoiced to client (€)"
            class="px-4"
            type="number"
          ></v-text-field>
        </v-card>
      </div>

      <div v-if="test.participants.length > 0">
        <v-card flat class="pa-5 mr-7 mb-7 my-7" style="width: 400px">
          <v-card-title class="headline"><b>Participants</b></v-card-title>
          <v-divider class="mb-4 mx-4"></v-divider>
          <v-data-table
            :headers="headers"
            :items="participants"
            sort-by="start"
            :sort-desc="[true]"
            class="mx-4"
          >
          </v-data-table>
        </v-card>
      </div>
    </div>

    <v-snackbar v-model="snackbar" :timeout="timeout" color="secondary">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}
</style>

<script>
import { db, auth } from "../firebase";

export default {
  data() {
    return {
      complex: [],
      date: "",
      dates: [],
      clientItems: ["Albert Heijn", "T-Mobile", "TUI", "Samsung"],
      criteriaItems: [],
      typeItems: ["Desktop", "Mobile", "Video"],
      paymentItems: ["Braingineers", "External"],
      timeItems: [
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ],
      tenItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      snackbar: false,
      participants: [],
      text: "Data updated!",
      timeout: 3000,
      menu: false,
      headers: [
        { text: "Name", value: "firstName" },
        { text: "Last name", value: "lastName" },
      ],
      min: 0,
      max: 100,
      range: [30, 60],
    };
  },
  mounted() {
    if (this.$route.query.new) {
      this.snackbar = true;
    }
  },
  computed: {
    test() {
      if (this.$store.state.loading.tests) {
        return null;
      } else {
        let test = this.$store.state.tests.find(
          (t) => t.uid === this.$route.params.id
        );
        return test;
      }
    },
  },
  methods: {
    fetchParticipants() {
      let list = this.test.participants;

      const vm = this;

      for (let x in list) {
        db.collection("users")
          .doc(list[x])
          .get()
          .then((doc) => {
            if (doc.exists) {
              vm.participants.push(doc.data());
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      }
    },
    async fetchComplex(criteria) {
      const vm = this;
      this.complex = [];
      criteria.forEach((element) => getDoc(element));
      function getDoc(element) {
        var docRef = db.collection("attributes").doc(element);

        docRef
          .get()
          .then(function(doc) {
            if (
              doc.data().type == "Multiple choice" ||
              doc.data().type == "Scale 1-10"
            ) {
              vm.complex.push(doc.data());
            } else {
              // doc.data() will be undefined in this case
              console.log("Not a MC or Scale question");
            }
          })
          .catch(function(error) {
            console.log("Error getting document:", error);
          });
      }
    },

    fetchDates() {
      const vm = this;
      this.dates = [];
      db.collection("tests")
        .doc(this.$route.params.id)
        .collection("timeslots")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            vm.dates.push(doc.data());
          });
        });
    },

    addDate() {
      const vm = this;
      db.collection("tests")
        .doc(this.$route.params.id)
        .collection("timeslots")
        .add({
          day: vm.date,
          start: "",
          end: "",
        })
        .then(function(docRef) {
          var ref = db
            .collection("tests")
            .doc(vm.$route.params.id)
            .collection("timeslots")
            .doc(docRef.id);

          return ref.update({
            uid: docRef.id,
          });
        })
        .then(function() {
          vm.fetchDates();
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    },
    saveTest() {
      var idRef = db.collection("tests").doc(this.$route.params.id);
      const vm = this;
      this.newTest = false;

      return idRef
        .update(vm.test)
        .then(function() {
          console.log("Document successfully updated!");
          vm.snackbar = true;
          vm.saveDates();
        })
        .then(function() {
          vm.$store.dispatch("fetchTests");
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    saveDates() {
      console.log(this.dates);
      const vm = this;
      this.dates.forEach((element) => update(element));
      function update(date) {
        var docRef = db
          .collection("tests")
          .doc(vm.$route.params.id)
          .collection("timeslots")
          .doc(date.uid);

        return docRef
          .update({
            day: date.day,
            start: date.start,
            end: date.end,
          })
          .then(function() {
            console.log("Document successfully updated!");
          })
          .catch(function(error) {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }
    },
    saveComplex() {},
    deleteDate(uid) {
      const vm = this;
      db.collection("tests")
        .doc(this.$route.params.id)
        .collection("timeslots")
        .doc(uid)
        .delete()
        .then(function() {
          console.log("Document successfully deleted!");
          vm.fetchDates();
        })
        .catch(function(error) {
          console.error("Error removing document: ", error);
        });
    },
    getAnswers(answers) {
      var newArray = [];
      answers.forEach((element) => newArray.push(element.answer));
      return newArray;
    },
    participantPage(item) {
      this.$router.push(`/participant/${item.uid}`);
    },
    consent(item) {
      this.$router.push({
        name: "Consent",
        path: "/consent",
        params: {
          ppID: item.uid,
          testID: this.$route.params.id,
        },
      });
    },
  },
};
</script>
