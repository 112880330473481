<template>
  <v-container class="pa-10">
    <span style="cursor: pointer" @click="back()"
      ><u>Back to participants</u></span
    >
    <h1 v-if="!success">Informed consent</h1>
    <h1 v-if="success">Success!</h1>
    <v-alert
      class="mt-7 primary--text"
      prominent
      type="info"
      v-if="
        success &&
          !this.ext &&
          (!participant.address ||
            !participant.postal ||
            !participant.phone ||
            !participant.city ||
            !participant.birthday ||
            !participant.sex)
      "
    >
      <v-row align="center">
        <v-col class="grow">
          We don't have all essential information of this participant. Please
          fill in their details in their profile.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" @click="goTo()">participant profile</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <div class="cards" v-if="!success">
      <v-card flat class="pa-5 mr-7 mb-7 my-7" style="width: 300px">
        <v-card-title class="headline"><b>Details</b></v-card-title>
        <v-divider class="mb-4 mx-4"></v-divider>
        <v-select
          v-model="payment"
          :items="paymentItems"
          label="Payment by"
          class="px-4"
          readonly
        ></v-select>
        <v-text-field
          label="First name"
          v-model="participant.firstName"
          value="First name"
          class="px-4"
          v-if="ext"
        ></v-text-field>
        <v-text-field
          label="Last name"
          v-model="participant.lastName"
          value="Last name"
          class="px-4"
          v-if="ext"
        ></v-text-field>
        <v-text-field
          label="Email"
          v-model="participant.email"
          value="Email"
          class="px-4"
          v-if="ext"
        ></v-text-field>
        <v-text-field
          v-if="!ext"
          label="Duration (minutes)"
          v-model="duration"
          value="duration"
          class="px-4"
        ></v-text-field>
        <v-text-field
          v-if="!ext"
          label="Incentive (€)"
          v-model="incentive"
          value="incentive"
          class="px-4"
        ></v-text-field>
        <v-select
          v-model="testsParticipated"
          :items="$store.state.tests"
          :loading="$store.state.loading.tests"
          item-text="name"
          item-value="uid"
          label="Test(s)"
          class="px-4"
          multiple
        ></v-select>
        <v-text-field
          v-if="!ext"
          label="IBAN"
          v-model="participant.iban"
          class="px-4"
        ></v-text-field>
      </v-card>
      <v-card flat class="pa-5 mr-7 mb-7 my-7" style="width: 800px">
        <v-card-title class="headline"><b>Information</b></v-card-title>
        <v-divider class="mb-4 mx-4"></v-divider>
        <p class="px-4">{{ consent.text }}</p>
      </v-card>
    </div>
    <v-btn
      v-if="!success"
      color="accent"
      class="primary--text"
      @click="accept()"
      >Accept</v-btn
    >
  </v-container>
</template>

<style scoped>
h1 {
  margin-top: 10px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}
</style>

<script>
import { db, auth } from "../firebase";
import firebase from "firebase/app";
import moment, { min } from "moment";

export default {
  data() {
    return {
      consent: "",
      companyRelation: null,
      duration: null,
      fullName: "",
      incentive: null,
      participant: {},
      paymentItems: ["Braingineers", "External"],
      payment: "",
      sessionTests: [],
      success: false,
      test: {},
      testsParticipated: [],
    };
  },
  created() {
    this.fetchData();
    if (this.$route.params.participant) {
      this.incentive = this.$route.params.participant.incentive;
      this.duration = this.$route.params.participant.duration;
      if (this.$route.params.participant.tests) {
        this.$route.params.participant.tests.forEach((t) =>
          this.testsParticipated.push(t.uid)
        );
      }
    }

    if (this.$route.params.participant.payment) {
      this.payment = this.$route.params.participant.payment;
    } else {
      this.payment = "Braingineers";
    }
  },
  computed: {
    participantUid: function() {
      return this.$route.params.participant.uid;
    },
    ext: function() {
      if (this.$route.params.participant.payment === "External") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async accept() {
      const vm = this;
      let relation = null;
      if (vm.participant.relation) {
        relation = vm.participant.relation;
      }

      if (!this.ext) {
        await db
          .collection("users")
          .doc(vm.participant.uid)
          .update({
            iban: vm.participant.iban,
            participations: firebase.firestore.FieldValue.arrayUnion({
              tests: vm.testsParticipated,
              payment: vm.payment,
              incentive: vm.incentive,
              duration: vm.duration,
              consent: true,
              consentText: vm.consent.text,
              timestamp: new Date(),
            }),
            participated: firebase.firestore.FieldValue.increment(1),
            lastTest: new Date(),
          });

        await db
          .collection("consents")
          .add({
            firstName: vm.participant.firstName,
            lastName: vm.participant.lastName,
            tests: vm.testsParticipated,
            incentive: Number(vm.incentive),
            iban: vm.participant.iban,
            ppUid: vm.participant.uid,
            time: new Date(),
            userUid: auth.currentUser.uid,
            consent: true,
            consentText: vm.consent.text,
            payment: vm.payment,
            payment_approved: false,
            relation: vm.participant.relation
              ? String(vm.participant.relation)
              : vm.companyRelation,
          })
          .then(function(docRef) {
            db.collection("consents")
              .doc(docRef.id)
              .update({
                uid: docRef.id,
              });

            if (!vm.participant.relation) {
              db.collection("companies")
                .doc("7Bgcfv3l3e4e8i2eHcRE")
                .update({
                  relations: firebase.firestore.FieldValue.increment(1),
                });

              db.collection("users")
                .doc(vm.participant.uid)
                .update({
                  relation: vm.companyRelation,
                });
            }
            console.log("Document written with ID: ", docRef.id);
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });

        if (this.$route.params.participant.rec_uid) {
          await db
            .collection("recruitments")
            .doc(this.$route.params.participant.rec_uid)
            .collection("slots")
            .doc(this.$route.params.participant.slot_uid)
            .update({
              "participant.accepted": new Date(),
            });
        }
      }

      this.testsParticipated.forEach(async (uid) => {
        await db
          .collection("tests")
          .doc(uid)
          .update({
            participants: firebase.firestore.FieldValue.arrayUnion(
              vm.participant.uid
            ),
          });
      });

      if (this.ext) {
        db.collection("consents")
          .add({
            firstName: vm.participant.firstName,
            lastName: vm.participant.lastName,
            tests: vm.testsParticipated,
            time: new Date(),
            userUid: auth.currentUser.uid,
            consent: true,
            consentText: vm.consent.text,
            payment: vm.payment,
            email: vm.participant.email,
          })
          .then(function(docRef) {
            console.log("Document written with ID: ", docRef.id);
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });
      }
      this.$store.dispatch("fetchTests");
      this.$store.dispatch("fetchParticipants");
      this.success = true;
    },
    back() {
      this.$router.push("/participants");
    },
    fetchData() {
      const vm = this;

      if (vm.participantUid) {
        var docRef = db.collection("users").doc(vm.participantUid);
        docRef
          .get()
          .then(function(doc) {
            if (doc.exists) {
              vm.participant = doc.data();
            } else {
              console.log("No such document!");
            }
          })
          .catch(function(error) {
            console.log("Error getting document:", error);
          });
      }

      db.collection("companies")
        .doc("7Bgcfv3l3e4e8i2eHcRE")
        .get()
        .then(function(doc) {
          if (doc.exists) {
            vm.companyRelation = doc.data().relations;
          } else {
            console.log("No such document!");
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error);
        });

      db.collection("defaults")
        .doc("consent")
        .get()
        .then(function(doc) {
          if (doc.exists) {
            vm.consent = doc.data();
          } else {
            console.log("No such document!");
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error);
        });

      db.collection("settings")
        .doc("incentives")
        .get()
        .then(function(doc) {
          if (doc.exists) {
            vm.incentives = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error);
        });
    },
    goTo() {
      this.$router.push(`/participant/${this.participant.uid}`);
    },
  },
};
</script>
