<template>
  <v-container class="pa-10">
    <h1>Tests</h1>
    <v-btn
      color="accent"
      class="my-4 primary--text"
      @click="$router.push('/newtest')"
      >+ test</v-btn
    >
    <v-card class="px-5 pb-3 my-3" flat>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            color="primary"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <div class="d-flex" style="gap: 20px">
      <div style="max-width: 700px">
        <v-data-table
          :headers="headers"
          :items="$store.state.tests"
          :loading="$store.state.loading.tests"
          :search="search"
          show-select
          v-model="selected"
          item-key="uid"
          @click:row="testPage"
        >
          <template v-slot:item.participants="{ item }">
            <span>
              {{ item.participants.length > 0 ? item.participants.length : "" }}
            </span>
          </template>

          <template v-slot:item.approved="{ item }">
            <v-simple-checkbox
              v-model="item.approved"
              disabled
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon @click.stop="deleteItem(item)" class="mr-5">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
      <div>
        <v-card flat class="pa-5" style="width: 400px">
          <v-card-title class="headline primary--text"
            ><b>Earnings</b></v-card-title
          >
          <v-divider class="mb-4 mx-4"></v-divider>
          <p
            v-if="!$store.state.loading.tests"
            class="mx-4 secondary--text"
            style="font-size: 50pt; font-weight: 800;"
          >
            €{{ invoiced }}
          </p>
          <div v-else class="pa-5">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <p style="font-size: 10pt;">
            Total invoices to clients where we recruited participants ourselves.
          </p>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<style scoped></style>

<script>
import { db, auth } from "../firebase";
import Vuex from "vuex";
import { mapState } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      sex: "",
      sexItems: ["Male", "Female"],
      headers: [
        { text: "Name", value: "name" },
        { text: "Client", value: "client.name" },
        { text: "Delete", value: "actions" },
        { text: "Participants", value: "participants" },
      ],
      tests: [],
      selected: [],
      search: "",
    };
  },
  created() {
    // this.fetchData();
  },
  computed: {
    ...mapState(["userProfile", "invoiced"]),
  },
  methods: {
    deleteItem(item) {
      const vm = this;
      if (confirm("Are you sure that you want to delete this test?")) {
        db.collection("tests")
          .doc(item.uid)
          .delete()
          .then(function() {
            console.log("Document successfully deleted!");
          })
          .then(function() {
            vm.$store.dispatch("fetchTests");
          })
          .catch(function(error) {
            console.error("Error removing document: ", error);
          });
      } else {
        console.log("Item NOT deleted");
      }
    },

    testPage(item) {
      this.$router.push(`/test/${item.uid}`);
    },
    async newTest() {
      const vm = this;
      const company = this.$store.state.userProfile.company;
      db.collection("tests")
        .add({
          name: "New test",
          company: company,
          participants: 0,
          dates: [],
          client: "",
          duration: null,
          criteria: [],
          payment: "",
        })
        .then(function(docRef) {
          console.log("Document written with ID: ", docRef.id);

          var idRef = db.collection("tests").doc(docRef.id);

          return idRef
            .update({
              uid: docRef.id,
            })
            .then(function() {
              vm.$store.dispatch("fetchTests");
              vm.$router.push(`/test/${docRef.id}`);
            })
            .catch(function(error) {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    },
  },
};
</script>
