<template>
    <v-container>
        <div class="cards">
        <v-card flat class="pa-5 mr-7 mb-7" style="width: 400px">
        <p>{{ incentives }} </p>
        <v-row class="align-center">
            <v-col cols="12" md="5">
                <v-text-field
                v-model="incentive.description"
                label="Description"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field
                v-model="incentive.value"
                label="Value"
                type="number"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn @click="add">Add</v-btn>
            </v-col>
            
        </v-row>
        

        <div class="incentive"
        v-for="(item, index) in incentives"
        :key="index"
        >
        <v-row>
            <v-col
            cols="12"
            md="5"
            >
                <v-text-field
                
                ></v-text-field>
            </v-col>

            <v-col
            cols="12"
            md="4"
            >
                <v-text-field
                :value="item.value"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-btn>delete</v-btn>
            </v-col>
        </v-row>
        </div>
        </v-card>
        </div>
        <v-btn color="success" class="primary--text" @click="save()">Save</v-btn>
    </v-container>
</template>

<style scoped>

.cards {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
}

</style>

<script>
import { db, auth } from '../firebase'
import firebase from 'firebase/app'

export default {
    data() {
        return {
            incentives: [],
            incentive: {
                description: '',
                value: null,
            }
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        add() {
            const vm = this
            var docRef = db.collection("settings").doc("incentives");
            
            docRef.update({
                options: firebase.firestore.FieldValue.arrayUnion(vm.incentive)
            }).then(function() {
                vm.fetchData()
            })

        },
        fetchData() {
            const vm = this
            var docRef = db.collection("settings").doc("incentives");

            docRef.get().then(function(doc) {
                if (doc.exists) {
                    vm.incentives = doc.data().options
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        },
        save() {
            const vm = this
            return db.collection("settings").doc("incentives").update(vm.incentives)
            .then(function() {
                console.log("Document successfully updated!");
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });

        }
    }
}
</script>
