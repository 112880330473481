<template>
  <div class="access">
    <v-card class="mx-5">
      <v-card-title class="primary">
        <v-img
          contain
          max-width="75"
          class="mx-auto"
          src="@/assets/logo2.png"
        ></v-img>
      </v-card-title>
      <v-form v-model="valid" class="px-10 py-6" v-if="reset && !sent">
        <h1>Wachtwoord resetten</h1>
        <p>Vul uw email adres in en klik op 'Reset'.</p>
        <p @click="reset = false" style="cursor: pointer"><u>Inloggen</u></p>
        <v-container>
          <v-row align="center">
            <v-text-field
              v-model="resetEmail"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-btn color="accent primary--text" @click="resetPassword()"
              >Reset</v-btn
            >
          </v-row>
        </v-container>
      </v-form>
      <div class="px-10 py-6" v-if="sent">
        <h1>Wachtwoord resetten</h1>
        <p>Er is een email naar u gestuurd om het wachtwoord te resetten.</p>
        <p
          @click="
            reset = false;
            sent = false;
          "
          style="cursor: pointer"
        >
          <u>Opnieuw inloggen</u>
        </p>
      </div>

      <v-form v-model="valid" class="px-10 py-6" v-if="!reset">
        <h1>Inloggen</h1>
        <p>
          Login met je email en wachtwoord, of gebruik de knop om met Google in
          te loggen.
        </p>
        <span>Heb je nog geen account? </span
        ><router-link to="/signup">Inschrijven</router-link>
        <p style="cursor: pointer" @click="reset = true">
          <u>Wachtwoord resetten</u>
        </p>
        <v-container>
          <v-row align="center">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              required
              class="pr-5"
            ></v-text-field>

            <v-btn
              :loading="loading"
              color="accent primary--text"
              @click="
                login();
                loading = true;
              "
              >Go!</v-btn
            >
          </v-row>
        </v-container>
        <p v-if="error" class="error--text">
          Je hebt een verkeerd wachtwoord of emailadres ingevoerd, óf je hebt je
          aangemeld door in te loggen met Google. Probeer het opnieuw, of klik
          op 'wachtwoord resetten'.
        </p>
      </v-form>
      <v-divider></v-divider>
      <v-row class="my-3">
        <v-col cols="12" class="px-13 mb-4">
          <v-btn block @click="google" color="primary" height="50">
            <v-icon class="mr-3">
              mdi-google
            </v-icon>
            Sign in with Google
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<style scoped>
.access {
  margin: 10% auto;
  max-width: 500px;
}
</style>

<script>
import router from "../router/index";
import firebase from "firebase";
import * as fb from "../firebase";
import Vuex from "vuex";
import { db } from "../firebase";

export default {
  data: () => ({
    valid: false,
    firstname: "",
    lastname: "",
    email: "",
    error: false,
    errorText: "",
    loading: false,
    password: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    reset: false,
    resetEmail: "",
    sent: false,
  }),
  computed: {},
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.errorText = err.message;
          this.loading = false;
        });
    },
    companyAccess() {
      return this.$route.params.id;
    },
    async google() {
      const provider = new firebase.auth.GoogleAuthProvider();
      const vm = this;
      await firebase
        .auth()
        .signInWithPopup(provider)
        .then(function(result) {
          var user = result.user;
          var n = result.user.email.endsWith("@braingineers.com");
          if (n) {
            if (result.additionalUserInfo.isNewUser) {
              console.log("new user");
              const name = result.user.displayName;
              const firstName = name.split(" ");
              db.collection("users")
                .doc(result.user.uid)
                .set({
                  uid: result.user.uid,
                  email: result.user.email,
                  company: vm.checkDomain(result.user.email),
                  firstName: firstName[0],
                  role: "admin",
                });
            } else {
              console.log("existing user");
            }
          } else {
            if (result.additionalUserInfo.isNewUser) {
              console.log("new user");
              const name = result.user.displayName;
              const firstName = name.split(" ");
              db.collection("users")
                .doc(result.user.uid)
                .set({
                  uid: result.user.uid,
                  email: result.user.email,
                  firstName: firstName[0],
                  role: "participant",
                  attributes: [],
                  company: "7Bgcfv3l3e4e8i2eHcRE",
                  participations: [],
                });
            } else {
              console.log("existing user");
            }
          }
        });
    },
    checkDomain(email) {
      var n = email.endsWith("@braingineers.com");
      if (n) {
        return "7Bgcfv3l3e4e8i2eHcRE";
      } else {
        return null;
      }
    },
    resetPassword() {
      var auth = firebase.auth();
      var emailAddress = this.resetEmail;
      const vm = this;

      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function() {
          console.log("email sent");
        })
        .then(() => {
          vm.sent = true;
        })
        .catch(function(error) {
          // An error happened.
        });
    },
  },
};
</script>
