import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Signin from "../views/Signin.vue";
import Participants from "../views/Participants.vue";
import Participant from "../views/Participant.vue";
import { auth } from "../firebase";
import Vuex from "vuex";

import Signup from "../views/Signup.vue";
import Settings from "../views/Settings.vue";
import Tests from "../views/Tests.vue";
import Test from "../views/Test.vue";
import Setting from "../views/Setting.vue";
import Consent from "../views/Consent.vue";
import Sessions from "../views/Sessions.vue";
import Session from "../views/Session.vue";
import Email from "../views/Email.vue";
import Questionnaire from "../views/Participants/Questionnaire.vue";
import Verify from "../views/Participants/Verify.vue";
import Confirm from "../views/Participants/Confirm.vue";
import NewTest from "../views/NewTest.vue";
import Scheduler from "../views/Participants/Scheduler.vue";
import Recruitment from "../views/Recruitment.vue";
import Calendar from "@/views/Calendar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Scheduler,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/confirm",
    name: "Confirm",
    component: Confirm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/consent",
    name: "Consent",
    component: Consent,
    props: true,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/email",
    name: "Email",
    component: Email,
    props: true,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/signup",
    name: "Signup to company",
    component: Signup,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/participants",
    name: "Participants",
    component: Participants,
    props: true,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/recruitments",
    name: "Recruitments",
    component: Recruitment,
    props: true,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/calendar-participants",
    name: "Calendar",
    component: Calendar,
    props: true,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/participant/:id",
    name: "Participant",
    component: Participant,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/questionnaire",
    name: "Questionnaire",
    component: Questionnaire,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/tests",
    name: "Tests",
    component: Tests,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/newtest",
    name: "NewTest",
    component: NewTest,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/test/:id",
    name: "Test",
    component: Test,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/setting/:id",
    name: "Setting",
    component: Setting,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: Sessions,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/session/:id",
    name: "Session",
    component: Session,
    meta: {
      requiresAuth: true,
      braingineer: true,
    },
  },
  {
    path: "/verify",
    name: "Verify",
    component: Verify,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const braingineer =
    auth.currentUser != null &&
    auth.currentUser.email.endsWith("@braingineers.com");
  const requiresBrain = to.matched.some((x) => x.meta.braingineer);

  if (requiresAuth && !auth.currentUser) {
    if (to.path === "/calendar") {
      next("/signin?from=calendar");
    } else {
      next("/signin");
    }
  } else if (requiresAuth && requiresBrain && !braingineer) {
    next("/");
  } else {
    next();
  }
});

export default router;
