<template>
  <div>
    <div
      style="height: 80px"
      class=" d-flex align-center justify-space-between px-10"
    >
      <div class="d-flex" style="gap: 10px">
        <v-select
          :items="$store.state.tests"
          item-text="name"
          solo
          style="max-width: 200px"
          hide-details=""
          dense
          flat
          v-model="test"
          item-value="uid"
          label="Test"
          clearable
        ></v-select>
        <v-select
          style="max-width: 200px"
          :items="$store.state.recruitments"
          item-text="title"
          dense
          hide-details=""
          item-value="uid"
          solo
          flat
          v-model="recruitment"
          label="Recruitment"
          clearable
        ></v-select>
        <v-select
          :items="['BraingiLab1', 'BraingiLab2', 'Dentsu Lab']"
          item-text="name"
          v-model="lab"
          dense
          style="max-width: 200px"
          hide-details=""
          solo
          flat
          label="Lab"
          clearable
        ></v-select>
        <AddEvent />
      </div>

      <div class="d-flex align-center" style="gap: 20px">
        <span v-if="$refs.calendar">{{ $refs.calendar.title }}</span>
        <v-btn elevation="0" color="primary" outlined @click="today"
          >Today</v-btn
        >
        <v-btn icon outlined @click="prev()"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        ><v-btn icon outlined @click="next()"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </div>
    </div>
    <v-progress-linear
      v-if="$store.state.loading.calendar"
      absolute
      indeterminate
      top
    ></v-progress-linear>

    <v-calendar
      class="calendar"
      ref="calendar"
      color="primary"
      :events="events"
      :event-color="getEventColor"
      type="week"
      weekdays="1,2,3,4,5,6,0]"
      v-model="$store.state.calendar_preset"
      @click:event="showEvent"
    >
      <template v-slot:day-body="{ date, week }">
        <div
          class="v-current-time"
          :class="{ first: date === week[0].date }"
          :style="{ top: nowY }"
        ></div>
      </template>
    </v-calendar>
    <Panel
      v-if="selectedEvent"
      @close="drawer = false"
      :event="selectedEvent"
      :drawer.sync="drawer"
    />
    <CalendarBraingineers
      :event="selectedEvent"
      :drawer.sync="drawer_braingineers"
      @close="drawer_braingineers = false"
      v-if="drawer_braingineers"
    />
  </div>
</template>
<script>
import firebase from "firebase";
import moment from "moment";
import { db, auth } from "../firebase";
import Panel from "../components/CalendarPanel.vue";
import AddEvent from "@/components/AddEvent.vue";
import CalendarBraingineers from "@/components/CalendarBraingineers.vue";

export default {
  components: {
    Panel,
    AddEvent,
    CalendarBraingineers,
  },
  watch: {
    drawer(val) {
      console.log(val);
    },
  },
  data: () => ({
    drawer: false,
    drawer_braingineers: false,
    lab: "",
    recruitment: "",
    test: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    ready: false,
  }),
  async mounted() {
    // this.$refs.calendar.scrollToTime("08:00");
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
  computed: {
    value: {
      get() {
        return this.$store.state.calendar_preset;
      },
      set(value) {
        this.$store.commit("setCalendarPreset", value);
      },
    },
    events() {
      let events = this.$store.state.calendar;

      if (this.lab) {
        events = events.filter((e) => e.rec && e.rec.lab === this.lab);
      }

      if (this.recruitment) {
        events = events.filter((e) => e.rec && e.rec.uid === this.recruitment);
      }

      if (this.test) {
        events = events.filter((e) => e.rec && e.rec.tests.includes(this.test));
      }
      return events;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
  },
  methods: {
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    today() {
      this.$store.commit("setCalendarPreset", "");
    },
    showEvent({ nativeEvent, event }) {
      this.selectedEvent = event;

      if (event.type === "braingineers") {
        this.drawer_braingineers = true;
      } else {
        this.drawer = true;
      }
    },
  },
};
</script>

<style scoped>
.calendar {
  height: calc(100vh - 155px);
}
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
