<template>
    <div>
        <!--
        <v-btn class="my-4 mr-5" @click="testMail">Test email</v-btn>
        <v-btn class="primary--text my-4" color="success" @click="send">Reminder email</v-btn>
        -->
        <v-card flat class="pa-8">
          <div class="top align-center mb-3">
            <h3 class="">Incomplete participants &#128106;</h3>
            <p>Participants who didn't accept our terms and filled in any attributes.</p>
            <v-spacer></v-spacer>
          </div>
            <v-data-table
            :headers="headers"
            :items="participants"
            sort-by="firstName"
            :sort-desc="[true]"
            >
            </v-data-table>
        </v-card>

        <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="secondary"
        >
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import { db, auth } from '../firebase'
import moment, { min } from 'moment';
import firebase from 'firebase/app'

export default {
    data() {
        return {
            headers: [
                {text: 'First name',value: 'firstName',},
                {text: 'Last name', value: 'lastName',},
                {text: 'Email', value: 'email',},
            ],
            participants: [],
            snackbar: false,
            text: 'Data updated!',
            timeout: 3000,
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            const vm = this
            db.collection("users").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    if (!doc.data().terms && doc.data().role != 'admin') {
                        vm.participants.push(doc.data())
                    }
                });
            });
        },
        testMail() {
            const vm = this
            const userEmail = auth.currentUser.email
            db.collection("mail").add({
                to: userEmail,
                template: {
                    name: 'reminder',
                    data: {
                        firstName: vm.participants[0].firstName,
                    }
                }
            })
            .then(function(docRef) {
                console.log('email sent to ' + userEmail);
                vm.snackbarAction('Test email sent to ' + userEmail)
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
        },
        send() {
            const group = this.participants
            group.forEach(element => {
                const vm = this
                db.collection("mail").add({
                    to: element.email,
                    template: {
                        name: 'reminder',
                        data: {
                            firstName: element.firstName,
                        }
                    }
                })
                .then(function(docRef) {
                    console.log('email sent to ' + element.email);
                    vm.snackbarAction('email sent to participants')
                })
                .catch(function(error) {
                    console.error("Error adding document: ", error);
                });
            })
        },
        snackbarAction(text) {
          this.text = text
          this.snackbar = true
        }
    }
}
</script>