<template>
  <v-container fluid>
    <div v-if="userProfile.role == 'admin'" class="pa-10">
      <h1>Goodday, {{ userProfile.firstName }} &#x270C;</h1>
      <p class="mb-6">Here's whats happening today..</p>
      <!-- <v-btn color="primary" class="mb-5" @click="consent">Consent form</v-btn> -->
      <div class="d-flex" style="gap: 25px; flex-wrap: wrap">
        <div>
          <Participants class="mr-5" />
        </div>
        <!-- <div>
          <v-card flat class="pa-5" style="width: 400px">
            <v-card-title class="headline primary--text"
              ><b>Earnings</b></v-card-title
            >
            <v-divider class="mb-4 mx-4"></v-divider>
            <p
              v-if="!$store.state.loading.tests"
              class="mx-4 secondary--text"
              style="font-size: 50pt; font-weight: 800;"
            >
              €{{ invoiced }}
            </p>
            <div v-else class="pa-5">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <p style="font-size: 10pt;">
              Total invoices to clients where we recruited participants
              ourselves.
            </p>
          </v-card>
        </div> -->
      </div>
    </div>
    <div v-if="userProfile.role == 'participant'" class="home">
      <div class="banner">
        <img src="@/assets/logo2.png" alt="" style="height: 60px" />
        <v-spacer></v-spacer>
        <div class="title">
          <h3>Welcome, <br />{{ userProfile.firstName }}!</h3>
        </div>
      </div>
      <v-progress-linear :value="progress" color="warning"></v-progress-linear>
      <div class="content mt-5 mx-6">
        <h1>Vragenlijst</h1>
        <p v-if="answeredAll">
          U heeft alle vragen beantwoord die nu openstaan.
        </p>

        <ul v-if="answeredAll">
          <li>
            Wij sturen je een uitnodiging per mail wanneer er een onderzoek is
            dat bij jou aansluit.
          </li>
          <li>Bepaal zelf of en wanneer je mee wilt doen aan het onderzoek.</li>
          <li>Doe mee aan het onderzoek en ontvang een leuke vergoeding!</li>
        </ul>
        <v-btn
          v-if="$store.state.recruitments.length > 0"
          @click="$router.push('/calendar')"
          class="mt-4 primary--text"
          elevation="0"
          color="success"
          >Bekijk lopende onderzoeken</v-btn
        >
      </div>
      <div class="content mt-5 mx-6" v-if="!answeredAll">
        <Questions @answered="changeAnsweredAll" />
      </div>
      <!--
      <div class="content mt-5 mx-6" v-if="answeredAll && !scheduled">
        <Sessions @scheduled="changeScheduled" />
      </div>
      -->
    </div>
  </v-container>
</template>

<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}

@media screen and (min-width: 800px) {
  .content {
    display: grid;
    width: 600px;
    margin: 0 auto;
  }
  .footer {
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    background-color: white;
    width: 100%;
  }
}

.banner {
  background-color: #0a0d3d;
  padding: 30px 30px;
  display: flex;
  flex-wrap: wrap;
}
.title {
  color: white;
  text-align: end;
}

.footer {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 30px 30px;
  border-top: 1px solid white;
}
.readmore {
  text-align: end;
  font-size: 10pt;
}

.readmore p {
  margin: 0 0;
}

.instructions {
  padding: 30px 0px;
}

.content p {
  font-size: 14pt;
}
</style>

<script>
import router from "../router/index";
import Vuex from "vuex";
import { mapState } from "vuex";
import { db, auth } from "../firebase";
import firebase from "firebase/app";
import Questions from "@/components/Questions.vue";
import Sessions from "@/components/Sessions.vue";
import Participants from "@/components/Participants.vue";

export default {
  name: "Home",
  components: {
    Questions,
    Sessions,
    Participants,
  },
  data() {
    return {
      answeredAll: false,
      progress: 0,
      scheduled: false,
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.userProfile,
      invoiced: (state) => state.invoiced,
    }),
  },
  methods: {
    changeAnsweredAll(value) {
      this.answeredAll = value;
    },
    changeScheduled(value) {
      this.scheduled = value;
    },
    consent() {
      this.$router.push({
        name: "Consent",
        path: "/consent",
        params: {
          participant: {
            payment: "External",
          },
        },
      });
    },
  },
};
</script>
