<template>
  <div class="access">
    <v-card>
      <v-card-title class="primary">
        <v-img
          contain
          max-width="75"
          class="mx-auto"
          src="@/assets/logo2.png"
        ></v-img>
      </v-card-title>

      <v-form v-model="valid" class="pa-10">
        <h1>Inschrijven</h1>
        <p>
          Heb je een <b>Google account?</b>
          <span @click="google"><u>Klik hier</u></span
          >.<br />
          Heb je al een account? Ga naar
          <router-link :to="'/signin'">Inloggen.</router-link>
        </p>
        <v-row>
          <v-col class="py-0">
            <v-text-field
              v-model="firstname"
              label="Voornaam"
              required
            ></v-text-field>
          </v-col>
          <v-col class="py-0">
            <v-text-field
              v-model="lastname"
              label="Achternaam"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <v-text-field v-model="phone" label="Telefoonnummer"></v-text-field>
          </v-col>
          <v-col class="py-0">
            <v-select
              label="Opleidingsniveau"
              v-model="education"
              :items="educationItems"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <v-select
              v-model="sex"
              :items="sexItems"
              label="Geslacht"
              item-value="value"
              item-text="text"
            ></v-select>
          </v-col>

          <v-col class="py-0">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Geboortedag"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="date"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="save"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-checkbox
          color="primary"
          v-model="check1"
          class="pt-0 mt-0"
          label="Ik heb geen hoofddeksel die ik liever niet afzet en geen dik krullend/kroeshaar."
        ></v-checkbox>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Waarom vragen we dit?
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Tijdens het onderzoek meten wij emoties. Om dit te kunnen doen
              plaatsen wij een EEG headset met sensoren op het hoofd en moeten
              de sensoren makkelijk bij de hoofdhuid komen. Als u dik krullend
              of kroeshaar heeft, werkt dit helaas niet.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row>
          <v-col> </v-col>
        </v-row>

        <v-row align="center" class="px-3">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-row>

        <v-row align="center" class="px-3">
          <v-text-field
            v-model="password"
            label="Wachtwoord"
            type="password"
            required
            class="pr-5"
          ></v-text-field>
          <v-btn color="accent primary--text" @click="signup()">Go!</v-btn>
        </v-row>

        <p class="error--text" v-if="error && errorText">{{ errorText }}</p>
      </v-form>
      <v-divider></v-divider>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Door u in te schrijven gaat u akkoord met onze privacy voorwaarden.
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Met uw inschrijving voor het onderzoekspanel van Braingineers gaat u
            ermee akkoord dat wij uw gegevens voor onbepaalde tijd opslaan in
            onze database. Wij zullen uw gegevens zorgvuldig behandelen en u
            uitsluitend benaderen voor het meewerken aan onze
            neuromarketing-onderzoeken. Wij zullen persoonsgegevens die te
            herleiden zijn tot u als persoon op geen enkele manier delen met
            derden, buiten de EU of met een internationale organisatie.
            Opdrachtgevers krijgen enkel geanonimiseerde data te zien van de
            deelnemers van een onderzoek. Doordat de data is geanonimiseerd is
            deze niet te herleiden naar individuen (denk bijv. aan leeftijd,
            geslacht en links- of rechtshandigheid). <br /><br />
            U kunt u, wanneer gewenst, op elk moment afmelden voor onze mailing
            door te mailen naar danique@braingineers.com. Wilt u ook dat uw
            persoonsgegevens voorgoed uit de database worden verwijderd? Geef
            dit dan per mail door. Contactgegevens van de
            verwerkingsverantwoordelijke: info@braingineers.com
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--
      <v-divider></v-divider>
      <v-row class="my-3">
        <v-col
        cols="12"
        class="px-13 mb-4"
        >
          <v-btn 
          block
          @click="google"
          color="primary"
          height="50"
          >
            <v-icon
            class="mr-3">
              mdi-google
            </v-icon>
            Sign up with Google
          </v-btn>
        </v-col>
      </v-row>
      -->
    </v-card>
  </div>
</template>

<style scoped>
.access {
  margin: 5% auto;
  max-width: 600px;
}

@media screen and (max-width: 800px) {
  .access {
    margin: 0 0;
  }
}
</style>

<script>
import router from "@/router/index";
import * as fb from "@/firebase";
import Vuex from "vuex";
import firebase from "firebase";

export default {
  data: () => ({
    check1: false,
    date: null,
    error: false,
    errorText: "",
    menu: false,
    valid: false,
    firstname: "",
    lastname: "",
    education: "",
    educationItems: [
      "VMBO/MAVO/LBO",
      "HAVO (HBS/MMS)",
      "VWO",
      "MBO",
      "HBO",
      "WO",
    ],
    email: "",
    password: "",
    phone: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    sex: "",
    sexItems: [
      {
        value: "Male",
        text: "Man",
      },
      {
        value: "Female",
        text: "Vrouw",
      },
    ],
  }),
  computed: {
    answeredAll: function() {
      if (this.check1) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  created() {},
  methods: {
    async google() {
      const provider = new firebase.auth.GoogleAuthProvider();
      const vm = this;
      await firebase
        .auth()
        .signInWithPopup(provider)
        .then(function(result) {
          var user = result.user;
          var n = result.user.email.endsWith("@braingineers.com");
          if (n) {
            if (result.additionalUserInfo.isNewUser) {
              console.log("new user");
              const name = result.user.displayName;
              const firstName = name.split(" ");
              db.collection("users")
                .doc(result.user.uid)
                .set({
                  uid: result.user.uid,
                  email: result.user.email,
                  company: vm.checkDomain(result.user.email),
                  firstName: firstName[0],
                  role: "admin",
                });
            } else {
              console.log("existing user");
            }
          } else {
            if (result.additionalUserInfo.isNewUser) {
              console.log("new user");
              const name = result.user.displayName;
              const firstName = name.split(" ");
              db.collection("users")
                .doc(result.user.uid)
                .set({
                  uid: result.user.uid,
                  email: result.user.email,
                  firstName: firstName[0],
                  role: "participant",
                  attributes: [],
                  company: "7Bgcfv3l3e4e8i2eHcRE",
                  participations: [],
                });
            } else {
              console.log("existing user");
            }
          }
          vm.$store.dispatch("fetchUserProfile", user);
        });
    },
    checkDomain(email) {
      var n = email.endsWith("@braingineers.com");
      if (n) {
        return "7Bgcfv3l3e4e8i2eHcRE";
      } else {
        return null;
      }
    },
    signup() {
      if (
        !this.firstname ||
        !this.lastname ||
        !this.date ||
        !this.phone ||
        !this.sex
      ) {
        this.error = true;
        this.errorText =
          "U bent een paar vragen over uzelf vergeten. Vul ze hierboven in aub.";
      } else if (!this.check1) {
        this.error = true;
        this.errorText =
          "U moet de voorwaarde hierboven accepteren om in te kunnen schrijven.";
      } else {
        this.$store
          .dispatch("signup", {
            email: this.email,
            password: this.password,
            firstName: this.firstname,
            lastName: this.lastname,
            phone: this.phone,
            sex: this.sex,
            education: this.education,
            company: "7Bgcfv3l3e4e8i2eHcRE",
            role: "participant",
            birthday: this.date,
            terms: true,
            privacy: true,
          })
          .catch((err) => {
            this.error = true;
            console.log(err);
            this.errorText = err.message;
            this.loading = false;
          });
      }
    },
    companyAccess() {
      return this.$route.params.id;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
