<template>
  <v-dialog v-model="drawer" width="500" persistent>
    <v-card class="" v-if="event && drawer">
      <div
        class="d-flex justify-space-between align-center mb-8 px-8 py-4"
        :class="{ error: !event.participant, success: event.participant }"
      >
        <h2>{{ event.name }}</h2>
        <v-btn icon @click="$emit('close')"
          ><v-icon color="white">mdi-close</v-icon></v-btn
        >
      </div>
      <div class="information mb-8 px-8">
        <div class="d-flex" style="gap: 40px">
          <div>
            <p style="font-size: 12px; font-weight: 600" class="mb-0">
              Timeslot:
            </p>
            <p>
              {{ event.start }}
              <v-chip
                class="ml-2 primary--text"
                :color="event.participant ? 'success' : 'error'"
                >{{ event.participant ? "Filled" : "Open" }}</v-chip
              >
            </p>
          </div>
          <div>
            <p style="font-size: 12px; font-weight: 600" class="mb-0">
              Duration:
            </p>
            <p>{{ event.rec.duration }}</p>
          </div>
          <div>
            <p style="font-size: 12px; font-weight: 600" class="mb-0">
              Incentive:
            </p>
            <p>{{ event.rec.incentive }}</p>
          </div>
        </div>
        <div class="pp" v-if="event.participant">
          <p style="font-size: 12px; font-weight: 600" class="mb-0">
            Participant:
          </p>
          <p>
            {{ event.participant.firstName }} ({{
              getAge(event.participant.birthday)
            }})
          </p>
        </div>
      </div>
      <div class="buttons px-8 pb-8">
        <div class="d-flex align-center mb-2">
          <p class="mb-0 mr-2" style="font-size: 12px; font-weight: 600">
            Actions
          </p>
          <v-divider></v-divider>
        </div>
        <div class="d-flex" style="gap:10px" v-if="!show_reason">
          <v-btn
            small
            elevation="0"
            color="primary"
            v-if="event.participant"
            @click="$router.push(`/participant/${event.participant.uid}`)"
            >Profile</v-btn
          >
          <v-btn
            small
            @click="email(event.participant.email)"
            outlined
            color="primary"
            v-if="event.participant"
            >Email
          </v-btn>
          <v-btn
            small
            elevation="0"
            class="mb-0"
            color="error"
            @click="removeSlot(event)"
            v-if="!event.participant"
            >Remove timeslot</v-btn
          >
          <v-btn
            small
            elevation="0"
            class="mb-0"
            color="error"
            v-if="event.participant"
            @click="show_reason = !show_reason"
            >Cancel</v-btn
          >
          <v-btn
            small
            elevation="0"
            class="mb-0"
            color="error"
            outlined
            v-if="event.participant"
            @click="noShow(event)"
            >No show</v-btn
          >
        </div>
        <div v-if="show_reason">
          <p style="font-size:12px;" class="mt-4 mb-2">
            Give a reason that will be shown in the cancellation email to the
            participant:
          </p>
          <v-text-field
            class=" mb-2"
            solo
            flat
            background-color="grey lighten-4"
            label="Reason"
            v-model="reason"
            hide-details=""
          ></v-text-field>
          <v-btn outlined small class="mr-2" @click="show_reason = !show_reason"
            >Back</v-btn
          >
          <v-btn small elevation="0" color="error" @click="cancel"
            >Confirm cancellation</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { db, auth } from "../firebase";
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: ["drawer", "event"],
  computed: {
    ...mapState(["userProfile", "recruitments"]),
  },
  data() {
    return {
      reason: "",
      show_reason: false,
    };
  },
  methods: {
    cancel() {
      db.collection("recruitments")
        .doc(this.event.rec.uid)
        .collection("slots")
        .doc(this.event.slot)
        .update({
          "participant.uid": null,
          "participant.filled": null,
        })
        .then(() => {
          db.collection("mail").add({
            to: this.event.participant.email,
            replyTo: "research@braingineers.com",
            from: "Braingineers <info@braingineers.com>",
            template: {
              name: "annulering_bg",
              data: {
                reason: this.reason,
                duration: this.event.rec.duration,
                date: moment(this.event.start)
                  .locale("nl")
                  .format("dddd DD MMMM HH:mm"),
              },
            },
          });
        })
        .then(() => {
          this.$store.dispatch("fetchRecruitments");
          this.$emit("close");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    email(e) {
      var mail = document.createElement("a");
      mail.href = `mailto:${e}`;
      mail.click();
    },
    getAge(d) {
      return moment().diff(d, "years", false);
    },
    noShow(event) {
      db.collection("recruitments")
        .doc(event.rec.uid)
        .collection("slots")
        .doc(event.slot)
        .update({
          "participant.uid": null,
          "participant.filled": null,
        })
        .then(() => {
          db.collection("mail").add({
            to: event.participant.email,
            replyTo: "research@braingineers.com",
            from: "Braingineers <info@braingineers.com>",
            template: {
              name: "noshow",
              data: {
                date: moment(this.event.start)
                  .locale("nl")
                  .format("dddd DD MMMM HH:mm"),
              },
            },
          });
        })
        .then(() => {
          this.$store.dispatch("fetchRecruitments");
          this.$emit("close");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    removeSlot(slot) {
      db.collection("recruitments")
        .doc(slot.rec.uid)
        .collection("slots")
        .doc(slot.slot)
        .delete()
        .then(() => {
          this.$store.dispatch("fetchRecruitments");
          this.$emit("close");
          console.log("Document successfully deleted!");
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
  },
};
</script>
