<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary lighten-2"
        dark
        elevation="0"
        v-bind="attrs"
        v-on="on"
      >
        Add event
      </v-btn>
    </template>

    <v-card width="300">
      <div>
        <v-date-picker v-model="day" no-title> </v-date-picker>

        <div class="px-4">
          <p>Start time:</p>
          <div
            class="d-flex"
            style="
          gap: 10px;"
          >
            <v-text-field label="Hours" v-model="hours"></v-text-field>
            <v-text-field label="Minutes" v-model="minutes"></v-text-field>
          </div>
          <div
            class="d-flex"
            style="
          gap: 10px;"
          >
            <v-text-field
              label="Duration (hours.)"
              v-model="duration_hours"
            ></v-text-field>
            <v-text-field
              label="Duration (min.)"
              v-model="duration"
            ></v-text-field>
          </div>
          <v-text-field label="Title" v-model="title"></v-text-field>
        </div>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="menu = false">
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="add">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { db } from "@/firebase";

export default {
  data: () => ({
    day: "",
    menu: false,
    hours: 10,
    minutes: 0,
    duration: 0,
    duration_hours: 1,
    title: "",
  }),
  methods: {
    add() {
      if (!this.day) {
        alert(
          `OMG ${this.$store.state.userProfile.firstName}, you forgot the date!`
        );
      } else if (
        String(this.hours).includes(":") ||
        String(this.minutes).includes(":") ||
        this.hours.length > 2 ||
        this.hours.length === 0 ||
        this.minutes.length > 2 ||
        this.minutes.length === 0 ||
        this.minutes > 59 ||
        this.hours > 23
      ) {
        alert(
          `OMG ${this.$store.state.userProfile.firstName}, you put the times wrong!`
        );
      } else {
        let new_hours = Number(this.hours) + Number(this.duration_hours);
        let new_minutes = Number(this.minutes) + Number(this.duration);

        db.collection("calendar")
          .add({
            name: this.title,
            start: `${this.day} ${this.hours}:${this.minutes}`,
            end: `${this.day} ${new_hours}:${new_minutes}`,
            color: "#0A0D3D",
            type: "braingineers",
          })
          .then((docRef) => {
            var ref = db.collection("calendar").doc(docRef.id);
            return ref
              .update({
                uid: docRef.id,
              })
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });
          })
          .then(() => {
            this.$store.dispatch("fetchCalendar");
            this.menu = false;
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      }
    },
  },
};
</script>
