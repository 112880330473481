var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10",staticStyle:{"max-width":"1200px"}},[_c('h1',[_vm._v("Recruitments")]),_c('NewRecruitment',{attrs:{"selected":_vm.recruitment},on:{"close":function($event){_vm.recruitment = null}}}),_c('v-data-table',{staticClass:"mt-4",attrs:{"no-data-text":"No recruitments found","headers":_vm.headers,"items":_vm.recruitments,"sort-by":"created_at","sort-desc":[true],"loading":_vm.$store.state.loading.recruitments},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'active')?_c('v-chip',{staticClass:"primary--text",attrs:{"color":"success"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":_vm.blink ? 'white' : 'success'}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(item.status))],1):_vm._e(),(item.status === 'paused')?_c('v-chip',{staticClass:"primary--text",attrs:{"color":"error lighten-2"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pause")]),_vm._v(" "+_vm._s(item.status))],1):_vm._e(),(item.status === 'finished')?_c('v-chip',{staticClass:"primary--text",attrs:{"color":"grey lighten-2"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-flag-checkered")]),_vm._v(" "+_vm._s(item.status))],1):_vm._e(),(item.status === 'stopped')?_c('v-chip',{staticClass:"primary--text",attrs:{"color":"grey lighten-2"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-alert-octagon")]),_vm._v(" "+_vm._s(item.status))],1):_vm._e()]}},{key:"item.participants",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPp(item))+" ")]}},{key:"item.invited",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.invited.length === item.batch.length }},[_vm._v(_vm._s(item.invited.length)+" / "+_vm._s(item.batch.length))])]}},{key:"item.backup_pp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.backup_pp.length)+" "),_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openBackup(item.backup_pp)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}},{key:"item.slots",fn:function(ref){
var item = ref.item;
return _vm._l((item.slots),function(i,index){return _c('p',{key:index,staticClass:"mb-0 pb-0"},[_vm._v(" "+_vm._s(_vm.getTime(i.day))+" : "+_vm._s(i.start)+" - "+_vm._s(i.end)+" ")])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"elevation":"0"},on:{"click":function($event){_vm.recruitment = item}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit")],1),_c('v-btn',{staticClass:"ml-4",attrs:{"elevation":"0","icon":""},on:{"click":function($event){return _vm.deleteRec(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:"item.tests",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getTests(item.tests)),function(i,index){return _c('p',{key:index,staticClass:"mb-0 pb-0"},[_vm._v(" "+_vm._s(i)+" ")])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }