<template>
    <v-container fluid>
        <div class="banner">
            <img src="@/assets/logo2.png" alt="" style="height: 60px">
            <v-spacer></v-spacer>
            <!--
            <div class="title">
                <h3 v-if="!confirmed">Bevestigen</h3>
                <h3 v-if="answeredAll">Bevestigd!</h3>
            </div>
            -->
        </div>
        <div class="mx-7 my-7" v-if="!confirmed">
            <h1>Leuk dat je mee wilt doen!</h1> <br>
            <p>
                Doorloop nog enkele vragen om je inschrijving succesvol af te ronden, dit kost slechts een paar minuten. 
            </p>
            <p> Vanwege onze onderzoeksmethode zijn er twee voorwaarden die voor alle onderzoeken van toepassing zijn: 
                <br><br>
            <ul>
                <li>Omdat wij gebruik maken van een EEG headset, een cap die we op je hoofd plaatsen, 
                is het van belang dat je <u>geen</u> hoofddeksel draagt die je liever niet afzet (bijvoorbeeld een hijab) 
                en <u>geen</u>  dik krullend/kroeshaar hebt. </li>
            </ul>
                Voldoe je niet aan deze voorwaarden 
                dan is het helaas niet mogelijk om je inschrijving verder af te ronden. 
                <br><br>
            </p>
            <v-card flat class="pa-4 mb-4">
                <b>Voorwaarden</b><br>
                <v-checkbox color="primary" v-model="check2" class="pt-0 mt-0" label="Ik heb GEEN hoofddeksel die ik liever niet afzet en GEEN dik krullend/kroeshaar."></v-checkbox>
            </v-card>
            <v-card flat>
                <v-checkbox class="pl-5 pt-5" color="primary" v-model="check3" label="Ik ga akkoord met onderstaande privacy statement."></v-checkbox>
                <v-expansion-panels class="my-4">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Privacy statement
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Met uw inschrijving voor het onderzoekspanel van Braingineers gaat u ermee akkoord dat wij 
                            uw gegevens voor onbepaalde tijd opslaan in onze database. Wij zullen uw gegevens zorgvuldig 
                            behandelen en u uitsluitend benaderen voor het meewerken aan onze neuromarketing-onderzoeken. 
                            Wij zullen persoonsgegevens die te herleiden zijn tot u als persoon op geen enkele manier 
                            delen met derden, buiten de EU of met een internationale organisatie. Opdrachtgevers krijgen 
                            enkel geanonimiseerde data te zien van de deelnemers van een onderzoek. Doordat de data is 
                            geanonimiseerd is deze niet te herleiden naar individuen (denk bijv. aan leeftijd, geslacht en 
                            links- of rechtshandigheid). <br><br> U kunt u, wanneer gewenst, op elk moment afmelden voor onze mailing 
                            door te mailen naar danique@braingineers.com. Wilt u ook dat uw persoonsgegevens voorgoed uit de 
                            database worden verwijderd? Geef dit dan per mail door. Contactgegevens van de 
                            verwerkingsverantwoordelijke: info@braingineers.com
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
            
        </div>
        <div v-if="!confirmed">
            <p v-if="allChecked" class="primary--text mx-8"><i>Om de inschrijving te voltooien moet u <u>alle bovenstaande voorwaarden aanvinken</u>.</i></p>
            <v-btn :disabled="allChecked" color="success" @click="confirm(); confirmed = true" class="ml-7 mt-4 mb-8 primary--text">Bevestigen</v-btn>
        </div>
        <div class="mx-7 my-7" v-if="confirmed && !answeredAll">
            <h1>Vragenlijst</h1>
            <Questions @answered="changeAnsweredAll" />
        </div>
        <div class="mx-7 my-7" v-if="answeredAll">
            <h1>Success!</h1>
            <p>Dank je wel voor het inschrijven! Wat kun je verwachten?</p>
            <ul>
                <li>Wij sturen je een uitnodiging per mail wanneer er een onderzoek is dat bij jou aansluit. </li>
                <li>Bepaal zelf of en wanneer je mee wilt doen aan het onderzoek.</li>
                <li>Doe mee aan het onderzoek en ontvang een leuke vergoeding!</li>
            </ul>
        </div>
    </v-container>
</template>

<style scoped>

.banner {
  background-color: #0A0D3D;
  padding: 30px 30px;
  display: flex;
  flex-wrap: wrap;
}

.title {
  color: white;
  text-align: end;
}

</style>

<script>
import { mapState } from 'vuex'
import { db, auth } from '@/firebase'
import Questions from '@/components/Questions.vue'

export default {
    components: {
        Questions
    },
    data() {
        return {
            answeredAll: false,
            check1: false,
            check2: false,
            check3: false,
            confirmed: false,
        }
    },
    computed: {
        ...mapState(['userProfile']),
        allChecked: function() {
            if (this.check2 && this.check3) {
                return false
            } else {
                return true
            }
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        confirm() {
            var userRef = db.collection("users").doc(auth.currentUser.uid);

            // Set the "capital" field of the city 'DC'
            return userRef.update({
                terms: true,
                privacy: true,
            })
            .then(function() {
                console.log("Document successfully updated!");
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        },
        fetchData() {
            const vm = this
            var docRef = db.collection("users").doc(auth.currentUser.uid);

            docRef.get().then(function(doc) {
                if (doc.exists) {
                    if (doc.data().terms && doc.data().privacy) {
                        vm.confirmed = true
                        vm.$router.push('/questionnaire')
                    }
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        },
        changeAnsweredAll(value) {
            this.answeredAll = value
        },
    }
    
}
</script>
