import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";
import router from "../router/index";
import { db } from "../firebase";
import firebase from "firebase/app";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userProfile: {},
    participants: [],
    loading: {
      participants: false,
      tests: false,
      companies: false,
      attributes: false,
      recruitments: true,
      calendar: false,
    },
    calendar: [],
    calendar_preset: "",
    todays: [],
    tests: [],
    invoiced: 0,
    attributes: [],
    companies: [],
    recruitments: [],
  },
  mutations: {
    setTodays(state, val) {
      state.todays = val;
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setParticipants(state, val) {
      state.participants = val;
    },
    setLoadingParticipants(state, val) {
      state.loading.participants = val;
    },
    setLoadingTests(state, val) {
      state.loading.tests = val;
    },
    setLoadingAttributes(state, val) {
      state.loading.attributes = val;
    },
    setLoadingCompanies(state, val) {
      state.loading.companies = val;
    },
    setLoadingRecruitments(state, val) {
      state.loading.recruitments = val;
    },
    setLoadingCalendar(state, val) {
      state.loading.calendar = val;
    },
    setTests(state, val) {
      state.tests = val;
    },
    setInvoiced(state, val) {
      state.invoiced = val;
    },
    setAttributes(state, val) {
      state.attributes = val;
    },
    setCompanies(state, val) {
      state.companies = val;
    },
    setRecruitments(state, val) {
      state.recruitments = val;
    },
    setCalendar(state, val) {
      state.calendar = val;
    },
    setCalendarPreset(state, val) {
      state.calendar_preset = val;
    },
  },
  actions: {
    async fetchTests({ commit, state }) {
      commit("setLoadingTests", true);
      let tests = [];
      let invoiced = 0;
      db.collection("tests")
        .where("company", "==", state.userProfile.company)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            tests.push(doc.data());
            if (doc.data().invoiced) {
              invoiced = invoiced + Number(doc.data().invoiced);
            }
          });
        })
        .then(() => {
          tests.sort((a, b) =>
            a.created_at < b.created_at
              ? 1
              : b.created_at < a.created_at || !b.created_at
              ? -1
              : 0
          );
        })
        .then(() => {
          commit("setTests", tests);
          commit("setInvoiced", invoiced);
          commit("setLoadingTests", false);
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    async fetchCompanies({ commit, state }) {
      let companies = [];
      commit("setLoadingCompanies", true);

      db.collection("companies")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            if (doc.data().type === "client") {
              companies.push(doc.data());
            }
          });
        })
        .then(() => {
          companies.sort((a, b) =>
            a.name < b.name ? -1 : b.name < a.name ? 1 : 0
          );
        })
        .then(() => {
          commit("setCompanies", companies);
          commit("setLoadingCompanies", false);
        });
    },
    async fetchRecruitments({ commit, dispatch }) {
      console.log("fetching recruitments");
      let recs = [];
      commit("setLoadingRecruitments", true);
      commit("setLoadingCalendar", true);

      db.collection("recruitments")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            recs.push(doc.data());
          });
        })
        .then(async () => {
          await Promise.all(
            recs.map(async (r) => {
              r.timeslots = [];
              await db
                .collection("recruitments")
                .doc(r.uid)
                .collection("slots")
                .get()
                .then(function(querySnapshot) {
                  querySnapshot.forEach(function(slot) {
                    r.timeslots.push(slot.data());
                  });
                });
            })
          );
        })
        .then(() => {
          recs.sort((a, b) =>
            a.created_at < b.created_at
              ? -1
              : b.created_at < a.created_at
              ? 1
              : 0
          );
        })
        .then(() => {
          commit("setRecruitments", recs);
          dispatch("todays_participants");
          dispatch("fetchCalendar");
          commit("setLoadingRecruitments", false);
        });
    },
    async fetchCalendar({ commit, state }) {
      console.log("fetching calendar");
      let events = [];

      await db
        .collection("calendar")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            events.push(doc.data());
          });
        });

      await Promise.all(
        state.recruitments.map(async (r) => {
          r.test_names = [];

          await Promise.all(
            r.tests.map(async (test) => {
              await db
                .collection("tests")
                .doc(test)
                .get()
                .then((doc) => {
                  r.test_names.push(doc.data().name);
                });
            })
          );
          await Promise.all(
            r.timeslots.map(async (t) => {
              let pp = null;
              if (t.participant.uid) {
                await db
                  .collection("users")
                  .doc(t.participant.uid)
                  .get()
                  .then((doc) => {
                    pp = doc.data();
                  });
              }
              events.push({
                start: moment(t.start).format("YYYY-MM-DD HH:mm"),
                end: moment(t.end).format("YYYY-MM-DD HH:mm"),
                name: pp
                  ? `(${pp.sex.charAt(0)}) ${pp.firstName} (${r.title})`
                  : r.title,
                color: pp ? "success darken-2" : "error",
                rec: r,
                slot: t.uid,
                participant: pp ? pp : null,
              });
            })
          );
        })
      );

      commit("setCalendar", events);
      commit("setLoadingCalendar", false);
    },
    async todays_participants({ commit, state }) {
      let list = [];

      if (state.recruitments.length > 0) {
        for (let x in state.recruitments) {
          let r = state.recruitments[x];
          let tests = [];

          for (let z in r.tests) {
            await db
              .collection("tests")
              .doc(r.tests[z])
              .get()
              .then((doc) => {
                tests.push({
                  name: doc.data().name,
                  uid: doc.id,
                });
              });
          }

          for (let y in state.recruitments[x].timeslots) {
            let t = state.recruitments[x].timeslots[y];

            if (
              moment().format("YYYY-MM-DD") ===
                moment(t.start).format("YYYY-MM-DD") &&
              t.participant.uid
            ) {
              await db
                .collection("users")
                .doc(t.participant.uid)
                .get()
                .then((doc) => {
                  list.push({
                    name: `${doc.data().firstName}`,
                    uid: t.participant.uid,
                    rec_uid: r.uid,
                    slot_uid: t.uid,
                    start: t.start,
                    lab: r.lab,
                    tests: tests,
                    incentive: r.incentive,
                    duration: r.duration,
                    accepted: t.participant.accepted ? true : false,
                  });
                })
                .catch((error) => {
                  console.log("Error getting document:", error);
                });
            }
          }
        }
      }
      list.sort((a, b) => (a.start < b.start ? -1 : b.start < a.start ? 1 : 0));
      commit("setTodays", list);
    },
    async fetchParticipantRecruitments({ commit, state }) {
      console.log("fetchin recruitments");
      commit("setLoadingRecruitments", true);
      var recruitments = firebase.functions().httpsCallable("getRecruitments");
      const res = await recruitments({ uid: state.userProfile.uid });
      console.log(res.data);
      commit("setRecruitments", res.data);
      commit("setLoadingRecruitments", false);
    },
    async fetchAttributes({ commit, state }) {
      commit("setLoadingAttributes", true);
      let attributes = [];
      db.collection("attributes")
        .where("company", "==", state.userProfile.company)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            attributes.push(doc.data());
          });
        })
        .then(() => {
          commit("setAttributes", attributes);
          commit("setLoadingAttributes", false);
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    async fetchParticipants({ commit }) {
      commit("setLoadingParticipants", true);

      let participants = [];
      db.collection("users")
        .where("role", "==", "participant")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            if (doc.data().terms) {
              participants.push(doc.data());
            }
          });
        })
        .then(() => {
          commit("setParticipants", participants);
          commit("setLoadingParticipants", false);
        });
    },
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(
        form.email,
        form.password
      );

      // fetch user profile and set in state
      dispatch("fetchUserProfile", user);
    },
    async fetchUserProfile({ commit, dispatch }, user) {
      // fetch user profile
      var docRef = db.collection("users").doc(user.uid);
      const userProfile = await docRef.get();
      commit("setUserProfile", userProfile.data());

      if (userProfile.data().role === "admin") {
        dispatch("fetchParticipants");
        dispatch("fetchTests");
        dispatch("fetchCompanies");
        dispatch("fetchAttributes");
        dispatch("fetchRecruitments");
      } else {
        dispatch("fetchParticipantRecruitments");
      }
      // change route to dashboard
      if (!user.emailVerified) {
        router.push("/verify");
      } else if (
        !userProfile.data().terms &&
        userProfile.data().role === "participant"
      ) {
        router.push("/confirm");
      } else if (router.currentRoute.path.startsWith("/signin")) {
        if (router.currentRoute.query.from == "calendar") {
          router.push("/calendar");
        } else {
          router.push("/");
        }
      } else if (router.currentRoute.path.startsWith("/signup")) {
        router.push("/");
      }
    },
    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(
        form.email,
        form.password
      );

      // create user profile object in userCollections
      db.collection("users")
        .doc(user.uid)
        .set({
          firstName: form.firstName,
          lastName: form.lastName,
          education: form.education,
          sex: form.sex,
          phone: form.phone,
          company: form.company,
          email: form.email,
          uid: user.uid,
          role: form.role,
          birthday: form.birthday,
          attributes: [],
          lastTest: "",
          terms: form.terms,
          privacy: form.privacy,
          signed_up: new Date(),
        });
      dispatch("fetchUserProfile", user);
    },
    async logout({ commit }, uid) {
      await fb.auth.signOut();

      // clear userProfile and redirect to /login
      commit("setUserProfile", {});

      router.replace("/signin");
    },
  },
});
