<template>
  <div class="d-flex justify-center ">
    <v-overlay opacity="0.8" :value="$store.state.loading.recruitments">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-stepper
      v-model="step"
      v-if="mode === 'schedule'"
      style="border-radius: 0;"
      vertical
      class="mt-10"
      :style="{
        width: $vuetify.breakpoint.smAndUp ? '500px' : '100%',
        height: $vuetify.breakpoint.smAndUp ? '' : '100%',
      }"
    >
      <div class="primary d-flex justify-center align-center">
        <v-img height="25" class="my-5" contain src="@/assets/icon.png"></v-img>
      </div>

      <v-stepper-step :complete="step > 1" :step="1">
        Uw informatie
      </v-stepper-step>

      <v-stepper-content :step="1">
        <div>
          <p class="mb-1">
            <b> Uw gegevens:</b> <br />
            {{ userProfile.email }}
          </p>
          <p>
            <span style="font-size: 13px; cursor:pointer"
              ><u @click="logout">Bent u dit niet? Log uit.</u></span
            >
          </p>
        </div>
        <v-btn color="primary" @click="step = 2" small>
          Volgende
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" :step="2">
        Kies een dag
      </v-stepper-step>
      <v-stepper-content :step="2">
        <v-date-picker
          v-if="!$store.state.loading.recruitments"
          :allowed-dates="allowedDates"
          no-title
          v-model="picker"
          min="2022-06-15"
          max="2100-03-20"
        ></v-date-picker>

        <div>
          <v-btn small color="primary" @click="step = 3" :disabled="!picker">
            Volgende
          </v-btn>
          <v-btn small text @click="reset">
            Cancel
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" :step="3">
        Kies een tijd <span v-if="picker">op {{ getTime(picker) }}</span>
      </v-stepper-step>
      <v-stepper-content :step="3">
        <div class="mb-4">
          <div class="d-flex flex-column" style="gap: 10px; ">
            <v-btn
              @click="select(t)"
              v-for="t in timeslots"
              :key="t.uid"
              :outlined="!time || time.uid != t.uid"
              :color="!time || time.uid != t.uid ? 'primary' : 'success'"
              >{{ getTimeButton(t.start) }}</v-btn
            >
            <v-alert
              color="grey lighten-2"
              class="mb-0"
              v-if="picker && time && step === 3"
            >
              <div class="d-flex justify-space-between" style="gap:15px">
                <div>
                  <div>
                    <v-icon small class="mr-2">mdi-clock</v-icon>
                    <span class="mb-0" style="font-size:12px"
                      ><b
                        >{{
                          activeRecruitments.find(
                            (r) => r.uid === time.recruitment
                          ).duration
                        }}
                        Min.</b
                      ></span
                    >
                  </div>
                  <div>
                    <v-icon small size="20" class="mr-2"
                      >mdi-currency-eur</v-icon
                    >
                    <span class="mb-0" style="font-size:12px"
                      ><b
                        >{{
                          activeRecruitments.find(
                            (r) => r.uid === time.recruitment
                          ).incentive
                        }},-</b
                      ></span
                    >
                  </div>
                </div>

                <div style="max-width: 70%">
                  <v-icon small class="mr-2">mdi-map-marker</v-icon>
                  <span class="mb-0" style="font-size:12px"
                    ><b>{{
                      activeRecruitments.find((r) => r.uid === time.recruitment)
                        .location
                    }}</b></span
                  >
                </div>
              </div>
            </v-alert>
            <div v-if="timeslots.length === 0">
              <p>
                Sorry, er zijn geen tijden meer beschikbaar op deze dag.
              </p>

              <v-btn v-if="total_allowed_days.length > 1" @click="step = 2"
                >Kies andere dag</v-btn
              >
            </div>
          </div>
        </div>
        <div v-if="timeslots.length > 0">
          <v-btn :loading="signingup" small color="primary" @click="calendar">
            Aanmelden!
          </v-btn>
          <v-btn small text @click="step = 2">
            Vorige
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step :complete="step > 4" :step="4">
        Bevestiging
      </v-stepper-step>
      <v-stepper-content :step="4">
        <div v-if="!cancelled">
          <h3 class="mb-2">U staat ingeschreven!</h3>
          <p v-if="scheduled">
            We zien u graag op
            {{ getTime(scheduled.start) }} om
            {{ getTimeButton(scheduled.start) }} op locatie:
            {{
              recruitments.find((r) => r.uid === scheduled.recruitment)
                .location
            }}.
          </p>
          <p v-if="!scheduled && time">
            We zien u graag op
            {{ getTime(time.start) }} om {{ getTimeButton(time.start) }} op
            locatie:
            {{ recruitments.find((r) => r.uid === time.recruitment).location }}.
          </p>
          <p style="font-size: 12px" v-if="!scheduled && time">
            <v-icon x-small class="mr-1" color="success">mdi-email</v-icon> U
            ontvangt een email ter bevestiging.
          </p>
          <div class="d-flex flex-wrap" style="gap:10px">
            <v-btn elevation="0" @click="change(time)" outlined small
              >Wijzigen</v-btn
            >
            <v-btn elevation="0" @click="cancel(time)" outlined small
              >Annuleren</v-btn
            >
          </div>
          <!-- <div
            v-if="
              activeRecruitments.filter((r) => r.scheduled.length === 0)
                .length > 0
            "
          >
            <v-divider class="my-3"></v-divider>
            <p>U kunt zich voor een ander onderzoek ook inschrijven:</p>
            <v-btn small color="primary" @click="nextRecruitment">
              Volgende
            </v-btn> -->
          <!-- </div> -->
          <div v-if="appointments.length > 1">
            <p class="mt-6 mb-2"><b>Uw afspraken:</b></p>
            <v-alert
              style="cursor:pointer; font-size: 12px"
              class="primary--text mb-1"
              type="success"
              v-for="(i, index) in appointments"
              :key="index"
              @click="
                scheduled = i;
                step = 4;
                time = i;
                picker = null;
              "
              >U heeft een afspraak op {{ getTime(i.start) }}
              {{ getTimeButton(i.start) }}. Klik hier om deze te bekijken of
              wijzigen.</v-alert
            >
          </div>
        </div>

        <div v-if="cancelled">
          <h3 class="mb-2">Afspraak geannuleerd</h3>
          <p>
            Bedankt voor het doorgeven. U ontvangt een email als bevestiging van
            de annullering.
          </p>
        </div>
      </v-stepper-content>
    </v-stepper>

    <v-card v-if="mode === 'intro'" max-width="500" class="mt-10">
      <div class="pa-8">
        <div v-if="!$store.state.loading.recruitments">
          <h2 class="mb-2">Hallo, {{ userProfile.firstName }}</h2>
          <div v-if="appointments.length > 0">
            <p>U heeft de volgende afspraken staan:</p>
            <v-data-table
              :headers="headers2"
              :items="appointments"
              :items-per-page="-1"
              hide-default-footer
              hide-default-header
              mobile-breakpoint="100"
              class="mb-2"
              item-key="day"
            >
              <template v-slot:item.day="{ item }"
                ><b
                  >{{ getTime(item.start) }} {{ getTimeButton(item.start) }}</b
                >
                <p class="mb-1">{{ getLocation(item.recruitment) }}</p>
                <v-icon class="ml-1" color="success" v-if="isSelected(item)"
                  >mdi-check-circle</v-icon
                >
                <p class="mb-0" v-if="!canCancel(item)">
                  <i
                    >*Annuleren binnen 24u kan alleen telefonisch: 020 261
                    2438</i
                  >
                </p>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="canCancel(item)"
                  small
                  outlined
                  style="text-transform: none"
                  @click="cancel(item)"
                  >Annuleren</v-btn
                >
              </template>
            </v-data-table>
          </div>

          <div
            v-if="
              appointments.length === 0 &&
                waiting_list.length === 0 &&
                activeRecruitments.length === 0
            "
          >
            <p>
              Op het moment hebben we geen onderzoeken waarvoor u zich kunt
              inschrijven. U ontvangt automatisch een email wanneer er nieuwe
              onderzoeken beschikbaar zijn.
            </p>
          </div>

          <div
            class="mb-6"
            v-if="
              activeRecruitments.filter((r) => r.scheduled.length === 0)
                .length > 0
            "
          >
            <p>
              Klik hieronder om u in te schrijven voor een onderzoek.
            </p>
            <v-btn
              color="success"
              class="primary--text"
              @click="mode = 'schedule'"
              block
              >STArt</v-btn
            >
          </div>
        </div>

        <div
          v-if="
            waiting_list.length > 0 &&
              activeRecruitments.filter((r) => r.scheduled.length === 0)
                .length === 0
          "
        >
          <p><b>Wachtlijsten:</b></p>
          <v-data-table
            :headers="headers"
            :items="waiting_list"
            :items-per-page="-1"
            hide-default-footer
            hide-default-header
            mobile-breakpoint="100"
            class="mb-2"
            item-key="day"
          >
            <template v-slot:item.day="{ item }"
              >{{ getTime(item.day) }}
              <v-icon class="ml-1" color="success" v-if="isSelected(item)"
                >mdi-check-circle</v-icon
              ></template
            >

            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="!isSelected(item)"
                @click="addToWaitingList(item)"
                small
                style="text-transform: none"
                elevation="0"
                color="primary"
                >Inschrijven</v-btn
              >

              <v-btn
                v-if="isSelected(item)"
                small
                style="text-transform: none"
                elevation="0"
                color="primary"
                outlined
                @click="removeFromWaitinglist(item)"
                >Uitschrijven
              </v-btn>
            </template>
          </v-data-table>
        </div>

        <div v-if="waitinglist" class="mt-3">
          <p>
            U staat op onze wachtlijst en u zal een email ontvangen met een
            uitnodiging wanneer er plek vrij komt.
          </p>
          <p>
            Vriendelijke groet,
            <br />
            Team Braingineers
          </p>
        </div>
      </div>
    </v-card>

    <v-card v-if="mode === 'cancelled'" max-width="500" class="mt-10 pa-10">
      <h2>Afspraak geannuleerd</h2>
      <p>
        Uw afspraak is geannuleerd. We mailen u wanneer er een nieuw onderzoek
        beschikbaar komt.
      </p>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import firebase from "firebase";
import { db, auth } from "@/firebase";
import { DateTime } from "luxon";

export default {
  computed: {
    ...mapState(["userProfile", "recruitments", "loading"]),
    appointments() {
      let list = [];

      let filtered = this.recruitments.filter((r) =>
        r.scheduled.includes(this.userProfile.uid)
      );

      if (filtered.length > 0) {
        filtered.forEach((r) => {
          let x = r.timeslots.find(
            (t) => t.participant.uid === this.userProfile.uid
          );

          if (x !== undefined && moment().isBefore(x.end)) {
            list.push({
              ...x,
              recruitment: r.uid,
            });
          }
        });
      }

      return list;
    },
    activeRecruitments() {
      if (this.recruitments.length > 0) {
        let list = this.recruitments;
        list = list.filter((r) => {
          if (
            r.min_pp - r.pp_male === r.min_female &&
            this.userProfile.sex === "Male"
          ) {
            return false;
          } else if (
            r.min_pp - r.pp_female === r.min_male &&
            this.userProfile.sex === "Female"
          ) {
            return false;
          } else {
            return true;
          }
        });

        list = list.filter((r) => {
          return r.min_pp > r.pp_total;
        });

        return list.filter((r) => {
          return r.status === "active" || r.status === "paused";
        });
      } else {
        return [];
      }
    },
    waiting_list() {
      let list = [];

      if (this.recruitments.length > 0) {
        this.recruitments.forEach((r) => {
          let y = r.timeslots.find(
            (s) => s.participant.uid === this.userProfile.uid
          );
          if (y == undefined && r.status === "active") {
            r.slots.forEach((s) => {
              let x = list.find((w) => w.day === s.day);
              if (x !== undefined) {
                if (!x.recruitments.includes(r.uid)) {
                  x.recruitments.push(r.uid);
                }
              } else {
                list.push({
                  day: s.day,
                  recruitments: [r.uid],
                  backup_pp: r.backup_pp,
                });
                if (r.backup_pp.includes(this.userProfile.uid)) {
                  this.selected.push({
                    day: s.day,
                    recruitments: [r.uid],
                    backup_pp: r.backup_pp,
                  });
                  this.waitinglist = true;
                }
              }
            });
          }
        });
      }

      return list;
    },
    total_allowed_days() {
      let list = [];
      if (this.activeRecruitments.length > 0) {
        this.activeRecruitments.forEach((r) => {
          if (
            r.scheduled.length === 0 ||
            (this.changed_timeslot &&
              this.changed_timeslot.recruitment === r.uid)
          ) {
            r.allowed_days.forEach((d) => {
              if (!list.includes(d)) {
                list.push(d);
              }
            });
          }
        });
      }

      return list;
    },
    timeslots() {
      let list = [];
      if (this.activeRecruitments.length > 0) {
        this.activeRecruitments.forEach((r) => {
          if (
            (r.timeslots.length > 0 && r.scheduled.length === 0) ||
            (this.changed_timeslot &&
              this.changed_timeslot.recruitment === r.uid)
          ) {
            r.timeslots.forEach((t) => {
              if (moment(t.start).isAfter(moment())) {
                list.push({
                  ...t,
                  recruitment: r.uid,
                });
              }
            });
          }
        });
      }

      if (this.picker && list.length > 0) {
        list = list.filter((s) => {
          return moment(s.start).format("YYYY-MM-DD") === this.picker;
        });
      }
      return list;
    },
  },
  data() {
    return {
      changed: false,
      changed_timeslot: null,
      cancelled: false,
      invite: true,
      headers: [
        {
          text: "Day",
          align: "start",
          sortable: false,
          value: "day",
        },
        {
          text: "actions",
          value: "actions",
        },
      ],
      headers2: [
        {
          text: "Day",
          align: "start",
          sortable: false,
          value: "day",
        },
        {
          text: "actions",
          value: "actions",
        },
      ],
      mode: "intro",
      picker: null,
      ri: 0,
      step: 1,
      signingup: false,
      scheduled: null,
      selected: [],
      time: null,
      waitinglist: false,
    };
  },

  methods: {
    canCancel(i) {
      return moment(i.start).diff(moment(), "hours") > 24;
    },
    getLocation(i) {
      return this.recruitments.find((r) => r.uid === i).location;
    },
    async test() {
      let event = {
        summary: "123123",
        description: "2312312",
        start: {
          dateTime: "2022-11-29T10:00:00+01:00",
          timeZone: "Europe/Amsterdam",
        },
        end: {
          dateTime: "2022-11-29T11:00:00+01:00",
          timeZone: "Europe/Amsterdam",
        },
        location: "123",
        // attendees: [{ email: this.userProfile.email }],
        reminders: {
          useDefault: false,
          overrides: [
            { method: "email", minutes: 24 * 60 },
            { method: "popup", minutes: 10 },
          ],
        },
      };

      var test = firebase.functions().httpsCallable("calendar");
      const res = await test({ event: event });
      console.log(res);
    },
    addToWaitingList(i) {
      i.recruitments.forEach(async (r) => {
        await db
          .collection("recruitments")
          .doc(r)
          .update({
            backup_pp: firebase.firestore.FieldValue.arrayUnion(
              this.userProfile.uid
            ),
          })
          .then(() => {
            this.$store.dispatch("fetchParticipantRecruitments");
            this.waitinglist = true;
          });
      });
    },
    removeFromWaitinglist(i) {
      i.recruitments.forEach(async (r) => {
        await db
          .collection("recruitments")
          .doc(r)
          .update({
            backup_pp: firebase.firestore.FieldValue.arrayRemove(
              this.userProfile.uid
            ),
          })
          .then(() => {
            const index = this.selected.findIndex((s) => s.day === i.day);
            if (index > -1) {
              this.selected.splice(index, 1);
            }
            if (this.selected.length === 0) {
              this.waitinglist = false;
            }
            this.$store.dispatch("fetchParticipantRecruitments");
          });
      });
    },
    reset() {
      this.$store.dispatch("fetchParticipantRecruitments");
      this.mode = "schedule";
      this.step === 2;
      this.cancelled = false;
    },
    allowedDates(v) {
      if (
        this.total_allowed_days.length > 0 &&
        this.total_allowed_days.includes(v)
      ) {
        return true;
      } else {
        return false;
      }
    },
    change(t) {
      this.scheduled = t;
      this.time = t;
      this.changed_timeslot = t;
      this.step = 2;
      this.mode = "schedule";
    },
    async cancel(t) {
      const vm = this;
      await db
        .collection("recruitments")
        .doc(t.recruitment)
        .collection("slots")
        .doc(t.uid)
        .update({
          "participant.filled": null,
          "participant.uid": null,
        })
        .then(() => {
          this.$store.dispatch("fetchParticipantRecruitments");
          db.collection("mail").add({
            to: vm.userProfile.email,
            replyTo: "research@braingineers.com",
            from: "Braingineers <info@braingineers.com>",
            template: {
              name: "annulering_pp",
              data: {
                date: DateTime.fromISO(t.start, { setZone: true }).toFormat(
                  "ff"
                ),
              },
            },
          });
        });
      this.cancelled = true;
      this.step = 2;
      this.picker = null;
      this.time = null;
      this.scheduled = null;
      this.mode = "cancelled";
    },

    getTime(v) {
      return moment(v)
        .locale("nl")
        .format("dddd DD MMMM");
    },
    getTimeButton(v) {
      const t = DateTime.fromISO(v, { setZone: true }).toFormat("HH:mm");
      return t;
    },
    isSelected(i) {
      if (this.selected.some((e) => e.day === i.day)) {
        return true;
      } else {
        return false;
      }
    },
    logout() {
      this.$store.dispatch("logout", auth.currentUser.uid);
    },

    nextRecruitment() {
      this.step = 1;
    },
    select(t) {
      this.time = t;
    },
    async calendar() {
      this.signingup = true;
      let recruitment = this.recruitments.find(
        (r) => r.uid === this.time.recruitment
      );

      var check = firebase.functions().httpsCallable("checkSlotAvailability");
      const res = await check({
        recruitment: this.time.recruitment,
        slot: this.time.uid,
      });
      let filled = res.data;
      console.log(res);

      if (filled) {
        this.$store.dispatch("fetchParticipantRecruitments");
        window.alert(
          "Sorry, deze tijd is al gekozen of verwijderd. Kiest u a.u.b. een ander tijdslot."
        );
      }

      if (!filled) {
        if (this.changed_timeslot) {
          await db
            .collection("recruitments")
            .doc(recruitment.uid)
            .collection("slots")
            .doc(this.changed_timeslot.uid)
            .update({
              "participant.filled": null,
              "participant.uid": null,
            });
          this.changed = true;
        }

        // Fill timeslot in Firebase
        await db
          .collection("recruitments")
          .doc(recruitment.uid)
          .collection("slots")
          .doc(this.time.uid)
          .update({
            "participant.filled": new Date(),
            "participant.uid": this.userProfile.uid,
          });

        // Send confirmation email
        const vm = this;
        db.collection("mail")
          .add({
            to: vm.userProfile.email,
            replyTo: "research@braingineers.com",
            from: "Braingineers <info@braingineers.com>",
            template: {
              name: "pp_afspraak",
              data: {
                date: DateTime.fromISO(vm.time.start, {
                  setZone: true,
                }).toFormat("ff"),
                location: recruitment.location,
                incentive: recruitment.incentive,
                duration: recruitment.duration,
              },
            },
          })
          .then(function(docRef) {
            console.log("email sent to " + vm.userProfile.email);
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });

        // });
        this.$store.dispatch("fetchParticipantRecruitments");
        this.changed_timeslot = null;
        this.cancelled = false;
        this.step = 4;
        this.scheduled = null;
        this.signingup = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  .right {
    display: flex;
  }
}
</style>
