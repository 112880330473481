<template>
    <v-container>
        <div class="cards">
        <v-card flat class="pa-5 mr-7 mb-7" style="width: 80%" 
        v-for="(item, index) in defaults"
        :key="index"
        >
        <v-textarea
        v-model="item.text"
        :label="item.name"
        ></v-textarea>
        </v-card>
        </div>
        <v-btn color="success" class="primary--text" @click="saveDefaults()">Save</v-btn>
    </v-container>
</template>

<style scoped>

.cards {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
}

</style>

<script>
import { db, auth } from '../firebase'

export default {
    data() {
        return {
            defaults: []
        }
    },
    created() {
        this.fetchDefaults()
    },
    methods: {
        fetchDefaults() {
            const vm = this
            db.collection("defaults").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.defaults.push(doc.data())
                });
            });
        },
        saveDefaults() {
            this.defaults.forEach(saveItem)
            function saveItem(value) {
                var docRef = db.collection("defaults").doc(value.name);

                return docRef.update({
                    text: value.text
                })
                .then(function() {
                    console.log("Document successfully updated!");
                })
                .catch(function(error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });

            }
        }
    }
}
</script>
