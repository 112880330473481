<template>
<div>
        <v-row
        class="align-center"
        style="width: 510px"
        >
            <v-col
            cols="12"
            md="6">
                <v-text-field
                v-model="company"
                label="Add new company"
                ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="3"
            >
                <v-btn color="accent" class="primary--text" @click="newCompany()">Add company</v-btn>
            </v-col>
        </v-row>
        
        <div class="cards">
        <v-card flat class="mr-7 mb-7" style="width: 400px">
            <v-data-table
                :headers="headers"
                :items="companies"
                sort-by="name"
                :sort-desc="[false]"
            >
                <template v-slot:item.actions="{ item }">
                <v-icon
                @click.stop="deleteItem(item)"
                class="mr-7"
                >
                    mdi-delete
                </v-icon>
                </template>
            </v-data-table>
        </v-card>
        </div>
</div>
</template>

<style scoped>

.cards {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
}

</style>

<script>
import { db, auth } from '../firebase'

export default {
    data() {
        return {
            company: '',
            companies: [],
            headers: [
                {text: 'Name',value: 'name',},
                {text: 'Actions',value: 'actions',},
            ],
            incentives: {}
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        deleteItem(item) {
            const vm = this
            db.collection("companies").doc(item.uid).delete().then(function() {
                console.log("Document successfully deleted!");
            })
            .then(() => {
                vm.fetchData()
            })
            .catch(function(error) {
                console.error("Error removing document: ", error);
            });
        },
        fetchData() {
            this.companies = []
            const vm = this
            db.collection("companies").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    if (doc.data().type === 'client') {
                        vm.companies.push(doc.data())
                    }
                });
            });
        },
        newCompany() {
            const vm = this
            db.collection("companies").add({
                name: vm.company,
                type: 'client'
            })
            .then(function(docRef) {
                console.log(docRef.id)
                return db.collection("companies").doc(docRef.id).update({
                    uid: docRef.id
                })
                .then(function() {
                    console.log("Document successfully updated!");
                })
                .catch(function(error) {
                    console.error("Error updating document: ", error);
                });
            })
            .then(() => {
                console.log('fetching')
                vm.fetchData()
                vm.company = ''
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });

        }
    }
}
</script>
