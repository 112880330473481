<template>
    <v-container class="pa-10">
        <h1>{{ session.name }}</h1>

        <v-btn color="accent" class="primary--text mt-4" @click="save">Save</v-btn>
        
        <div class="cards">

            <v-card flat class="pa-5 mr-7 mb-2 mt-7" style="width: 400px">
                <v-card-title class="headline"><b>Setup</b></v-card-title>
                <v-divider class="mb-4 mx-4"></v-divider>
                <v-select
                :items="statusItems"
                v-model="session.status"
                label="Status"
                class="px-4"
                ></v-select>
                <v-text-field
                v-model="session.name"
                label="Name"
                class="px-4"
                ></v-text-field>
                <v-text-field
                v-model="session.incentive"
                label="Incentive"
                type="number"
                class="px-4"
                ></v-text-field>
                <v-select
                :items="paymentItems"
                v-model="session.payment"
                label="Payment by"
                class="px-4"
                ></v-select>
                <v-text-field
                v-model="session.duration"
                label="Duration (min.)"
                type="number"
                class="px-4"
                ></v-text-field>
                <v-select
                :items="tests"
                item-text="name"
                return-object
                v-model="session.tests"
                multiple
                label="Tests"
                class="px-4"
                ></v-select>
                <p class="px-4">Age range:</p>
                <v-range-slider
                    v-model="range"
                    :max="max"
                    :min="min"
                    hide-details
                    class="align-center px-4"
                >
                    <template v-slot:prepend>
                    <v-text-field
                        :value="range[0]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 40px"
                        @change="$set(range, 0, $event)"
                    ></v-text-field>
                    </template>
                    <template v-slot:append>
                    <v-text-field
                        :value="range[1]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 40px"
                        @change="$set(range, 1, $event)"
                    ></v-text-field>
                    </template>
                </v-range-slider>
                <v-select
                v-model="session.minMale"
                :items="tenItems"
                label="Minimum male participants"
                class="px-4 mt-5"
                ></v-select>
                <v-select
                v-model="session.minFemale"
                :items="tenItems"
                label="Minimum female participants"
                class="px-4"
                ></v-select>
                <v-select
                v-model="session.exclusion"
                :items="tests"
                item-text="name"
                item-value="uid"
                label="Did not participate at text X"
                class="px-4"
                multiple
                ></v-select>
                <v-autocomplete
                multiple
                :items="attributes"
                v-model="session.attributes"
                label="Attributes"
                item-text="name"
                return-object
                class="px-4"
                ></v-autocomplete>

                <div
                v-for="(item, index) in session.attributes"
                :key="index"
                class="px-4"
                >
                    <v-select
                    v-model="item.required"
                    :items="item.answers"
                    item-text="answer"
                    item-value="answer"
                    multiple
                    :label="item.description"
                    v-if="item.type === 'Multiple choice'"
                    ></v-select>

                    <v-select
                    v-model="item.required"
                    :items="tenItems"
                    multiple
                    :label="item.description"
                    v-if="item.type === 'Scale 1-10'"
                    ></v-select>

                    <v-select
                    v-model="item.required"
                    :items="YesNo"
                    :label="item.description"
                    v-if="item.type === 'Simple'"
                    ></v-select>

                    <v-text-field
                    v-model="item.required"
                    :label="item.description"
                    v-if="item.type === 'Open text'"
                    ></v-text-field>
                </div>

                <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-row class="align-center mr-1">
                            <v-col
                            cols="9"
                            >
                            <v-combobox
                            v-model="date"
                            chips
                            small-chips
                            label="Test dates"
                            prepend-icon="mdi-calendar"
                            readonly
                            class="px-4"
                            v-bind="attrs"
                            v-on="on"
                            ></v-combobox>
                            </v-col>
                            <v-col
                            cols="3"
                            >
                            <v-btn block @click="addDate()">Add</v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
                    </v-date-picker>
                    
                </v-menu>
                
            </v-card>
            
            <v-card flat class="pb-3 mr-7 my-7" style="height: 250px; width:300px" 
            v-for="(date, index) in session.dates"
            :key="index"
            >
            <v-card-title
            class="py-5 primary"
            >
            <v-row class="mx-3">
                <h3 class="headline white--text"><b>{{ getTime(date.day) }}</b></h3>
                <v-spacer></v-spacer>
                <v-btn @click="deleteDate(index)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-row>
            </v-card-title>
                <v-row>
                    <v-col
                    cols="12"
                    class="pt-5"
                    >
                        <v-autocomplete
                        :items="timeItems"
                        v-model="date.start"
                        label="Select start time"
                        class="px-4"
                        ></v-autocomplete>
                        <v-autocomplete
                        :items="timeItems"
                        v-model="date.end"
                        label="Select end time"
                        class="px-4"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                
            </v-card>
        </div>
        <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="secondary"
        >
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
    </v-container>
</template>

<style scoped>

.cards {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
}

</style>

<script>
import { db, auth } from '../firebase'
import moment, { min } from 'moment';
import firebase from 'firebase/app'


export default {
    data() {
        return {
            attributes: [],
            date: '',
            dates: [],
            headers: [
                {text: 'Name',value: 'name',},
                {text: 'Start time', value: 'time',},
                { text: 'Consent form // No Show', value: 'actions', sortable: false },
            ],
            menu: false,
            min: 0,
            max: 100,
            paymentItems: ['Braingineers','External'],
            range: [30,60],
            session: {},
            snackbar: false,
            statusItems: ['Open','Closed'],
            text: 'Data updated!',
            timeout: 3000,
            tests: [],
            tenItems: [1,2,3,4,5,6,7,8,9,10],
            timeItems: ['09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30'],
            participants: [],
            YesNo: ['Yes','No']
        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        anyParticipants: function() {
            return true
        }
    },
    methods: {
        addDate() {
            this.session.dates.push({
                day: this.date,
                start: '',
                end: '',
            })
            this.date = ''
        },
        consent(item) {
          console.log(item)
          this.$router.push({
              name: 'Consent',
              path: '/consent',
              params: {
                  participant: item,
              },
          })
        },
        deleteDate(number) {
            this.session.dates.splice(number,1)
        },
        fetchData() {
            const vm = this
            db.collection("sessions").doc(this.$route.params.id).get().then(function(doc) {
                if (doc.exists) {
                    vm.session = doc.data()
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });

            db.collection("attributes").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.attributes.push(doc.data())
                });
            });

            db.collection("tests").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.tests.push(doc.data())
                });
            });

            db.collection("users").where("role", "==", "participant")
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.participants.push(doc.data())
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            

        },
        getTime(time) {
          if (time) {
            return moment(time).format("DD-MM")
          }
        },
        noShow(uid) {
            console.log(uid)
          db.collection('users').doc(uid).update({
            noShow: firebase.firestore.FieldValue.increment(1)
          });
          this.snackbarAction(`No show added to ${item.firstName}!`)
        },
        save() {
            const vm = this
            const docRef = db.collection("sessions").doc(vm.$route.params.id)
            
            return docRef.update(vm.session)
            .then(function() {
                console.log("Document successfully updated!");
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        },
        snackbarAction(text) {
          this.text = text
          this.snackbar = true
        }
    }
    
}
</script>

