<template>
  <v-container>
    <div v-if="!confirmation && sessions.length != 0">
      <h1>Schedule a test!</h1>
      <p>Choose the session you would like to sign up for.</p>
    </div>
    <div v-if="!confirmation && sessions.length === 0">
      <h1>No sessions available</h1>
      <p>
        Unfortunately there are no sessions available at this moment. Please
        check again regularly.
      </p>
    </div>
    <div v-if="!selectedSession && !confirmation">
      <v-card
        class="px-4 mb-2 py-3"
        v-for="(session, index) in sessions"
        :key="index"
        @click="select(session)"
        flat
      >
        <h3>{{ session.name }}</h3>
        <div class="information">
          <div class="dates">
            <p><u>Available dates:</u></p>
            <div
              class="date"
              v-for="(date, index) in session.dates"
              :key="index"
            >
              {{ formatDate(date.day) }}: from {{ date.start }} to
              {{ date.end }}
            </div>
          </div>
          <div class="duration">
            <p><u>Duration:</u></p>
            {{ session.duration }} minutes
          </div>
          <div class="incentive">
            <p><u>Incentive:</u></p>
            €{{ session.incentive }}
          </div>
          <p style="font-size: 10pt">Click to see available timeslots</p>
        </div>
      </v-card>
    </div>
    <div v-if="selectedSession">
      <p style="cursor: pointer" @click="selectedSession = null">
        <u>Back to sessions</u>
      </p>
      <v-card class="mb-4 pa-6">
        <h3>Select day:</h3>
        <v-select
          v-model="selectedDay"
          :items="selectedSession.dates"
          item-text="day"
          return-object
          label="Select a day"
        ></v-select>
        <v-select
          v-if="selectedDay"
          v-model="selectedTimeslot"
          :items="getTimeslots()"
          item-text="start"
          return-object
          label="Select a time"
        ></v-select>
        <p style="font-size: 10pt" v-if="selectedDay">
          <i>The user test will take {{ selectedSession.duration }} minutes.</i>
        </p>
      </v-card>
      <v-btn
        class="success primary--text"
        v-if="selectedTimeslot"
        @click="confirm()"
        >Confirm!</v-btn
      >
    </div>

    <div v-if="confirmation">
      <v-card class="mb-4 pa-6" flat>
        <h3>Confirmation</h3>
        <p>Thanks for signing up. We've sent you an email as well!</p>
        <p><u>Day:</u> {{ selectedDay.day }}</p>
        <p><u>Time:</u> {{ selectedTimeslot }}</p>
        <p>Location: Johan Huizingalaan 763A, Amsterdam</p>
      </v-card>
    </div>
  </v-container>
</template>

<style scoped>
.information {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
</style>

<script>
import moment, { min } from "moment";
import { mapState } from "vuex";
import { db, auth } from "@/firebase";
import firebase from "firebase/app";

export default {
  data() {
    return {
      confirmation: false,
      participant: {},
      selectedDay: "",
      selectedSession: null,
      selectedTimeslot: "",
      sessions: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    confirm() {
      var docRef = db.collection("sessions").doc(this.selectedSession.uid);
      const vm = this;

      docRef
        .update({
          participants: firebase.firestore.FieldValue.arrayUnion({
            uid: auth.currentUser.uid,
            day: vm.selectedDay.day,
            time: vm.selectedTimeslot,
            sex: vm.participant.sex,
          }),
        })
        .then(() => {
          this.fetchData();
          this.confirmation = true;

          //this.$emit('scheduled', true)
        });
    },
    fetchData() {
      const vm = this;
      const uid = auth.currentUser.uid;

      db.collection("sessions")
        .where("status", "==", "Open")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            vm.sessions.push(doc.data());
          });
          vm.selectedSession = null;
        })
        .then(async () => {
          await db
            .collection("users")
            .doc(uid)
            .get()
            .then(function(doc) {
              if (doc.exists) {
                vm.participant = doc.data();
              } else {
                console.log("No such document!");
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error);
            });
        })
        .then(() => {
          this.filter();
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    filter() {
      let ses = this.sessions;
      const uid = auth.currentUser.uid;
      const pp = this.participant;

      function participated(obj) {
        if (!obj.participants) {
          return true;
        } else {
          if (obj.participants.find((val) => val.uid === uid)) {
            return false;
          } else {
            return true;
          }
        }
      }
      let filtered = ses.filter(participated);

      function exclusion(obj) {
        let score = 0;
        const participated = [];
        pp.participations.forEach((el) =>
          el.tests.forEach((val) => participated.push(val))
        );

        let score2 = 0;
        obj.exclusion.forEach((el) => {
          if (participated.includes(el)) {
            score2++;
          }
        });
        return score === 0 && score2 === 0;
      }

      if (pp.participations.length > 0) {
        filtered = filtered.filter(exclusion);
      }

      function sex(obj) {
        let score = 0;
        let males = 0;
        let females = 0;
        const sex = pp.sex;
        const min = 12;

        obj.participants.forEach((element) => {
          if (element.sex === "Male") {
            males++;
          } else {
            females++;
          }
        });

        if (obj.minMale > males && sex === "Male") {
          return true;
        } else if (obj.minFemale > females && sex === "Female") {
          return true;
        } else if (
          sex === "Male" &&
          obj.minMale < males &&
          min - males++ > minFemale
        ) {
          return true;
        } else if (
          sex === "Female" &&
          obj.minFemale < females &&
          min - females++ > minMale
        ) {
          return true;
        } else {
          return false;
        }
      }

      filtered = filtered.filter(sex);

      this.sessions = filtered;
    },
    formatDate(time) {
      return moment(time).format("DD MMMM");
    },
    getTimeslots() {
      //Data
      let x = this.selectedDay;
      const duration = this.selectedSession.duration;
      const pp = this.selectedSession.participants;
      const filteredPP = [];

      //Format the time
      let startTime = moment(x.start, "HH:mm");

      //Format the end time
      let endTime = moment(x.end, "HH:mm");
      let safeTime = endTime.add(1, "minutes");

      //Times
      let allTimes = [];

      //Loop over the times - only pushes time with 30 minutes interval
      while (startTime < endTime) {
        //Push times
        allTimes.push(startTime.format("HH:mm"));
        //Add interval of x minutes
        startTime.add(duration, "minutes");
      }

      // if last time + duration runs over the end time, remove this last timeslot
      if (startTime.add(duration, "minutes") > safeTime) {
        allTimes.pop();
      }

      function wrongDay(slot) {
        if (!slot) {
          return false;
        } else {
          if (slot.day === x.day) {
            return true;
          } else {
            return false;
          }
        }
      }

      function filled(time) {
        if (
          filteredPP.find((obj) => {
            return obj.time === time;
          })
        ) {
          return false;
        } else {
          return true;
        }
      }
      if (pp.length > 0) {
        filteredPP = pp.filter(wrongDay);
      }

      const filtered = allTimes.filter(filled);

      return filtered;
    },
    select(session) {
      this.selectedSession = session;
    },
  },
};
</script>
