<template>
  <div class="pa-10" style="max-width: 1200px;">
    <h1>Recruitments</h1>
    <!-- <v-btn @click="test">test token</v-btn> -->

    <NewRecruitment :selected="recruitment" @close="recruitment = null" />
    <v-data-table
      class="mt-4"
      no-data-text="No recruitments found"
      :headers="headers"
      :items="recruitments"
      sort-by="created_at"
      :sort-desc="[true]"
      :loading="$store.state.loading.recruitments"
    >
      <template v-slot:item.status="{ item }">
        <v-chip
          class="primary--text"
          color="success"
          v-if="item.status === 'active'"
          ><v-icon class="mr-2" small :color="blink ? 'white' : 'success'"
            >mdi-circle</v-icon
          >
          {{ item.status }}</v-chip
        >
        <v-chip
          color="error lighten-2"
          class="primary--text"
          v-if="item.status === 'paused'"
          ><v-icon class="mr-2" small>mdi-pause</v-icon>
          {{ item.status }}</v-chip
        >
        <v-chip
          class="primary--text"
          color="grey lighten-2"
          v-if="item.status === 'finished'"
          ><v-icon class="mr-2" small>mdi-flag-checkered</v-icon>
          {{ item.status }}</v-chip
        >
        <v-chip
          class="primary--text"
          color="grey lighten-2"
          v-if="item.status === 'stopped'"
          ><v-icon class="mr-2" small>mdi-alert-octagon</v-icon>
          {{ item.status }}</v-chip
        >
      </template>

      <template v-slot:item.participants="{ item }">
        {{ getPp(item) }}
      </template>

      <template v-slot:item.invited="{ item }">
        <span
          :class="{ 'error--text': item.invited.length === item.batch.length }"
          >{{ item.invited.length }} / {{ item.batch.length }}</span
        >
      </template>

      <template v-slot:item.backup_pp="{ item }">
        {{ item.backup_pp.length }}
        <v-btn class="ml-1" icon small @click="openBackup(item.backup_pp)"
          ><v-icon small>mdi-open-in-new</v-icon></v-btn
        >
      </template>

      <template v-slot:item.slots="{ item }">
        <p class="mb-0 pb-0" v-for="(i, index) in item.slots" :key="index">
          {{ getTime(i.day) }} : {{ i.start }} - {{ i.end }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex">
          <v-btn
            elevation="0"
            @click="recruitment = item"
            style="text-transform: none"
          >
            <v-icon small class="mr-2">mdi-pencil</v-icon> Edit</v-btn
          >
          <v-btn elevation="0" @click="deleteRec(item)" icon class="ml-4">
            <v-icon>mdi-delete</v-icon></v-btn
          >
        </div>
      </template>

      <template v-slot:item.tests="{ item }">
        <p
          class="mb-0 pb-0"
          v-for="(i, index) in getTests(item.tests)"
          :key="index"
        >
          {{ i }}
        </p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NewRecruitment from "@/components/NewRecruitment.vue";
import firebase from "firebase";
import moment from "moment";
import { db, auth } from "../firebase";

export default {
  components: {
    NewRecruitment,
  },
  computed: {
    ...mapState(["userProfile", "recruitments"]),
  },
  data() {
    return {
      blink: false,
      blink_interval: null,
      headers: [
        {
          text: "Status",
          value: "status",
        },
        { text: "Title", value: "title" },

        { text: "Speed", value: "speed" },
        { text: "Slots", value: "slots" },
        { text: "Tests", value: "tests" },
        {
          text: "Invited",
          value: "invited",
        },
        { text: "PP", value: "participants" },

        {
          text: "Backup",
          value: "backup_pp",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      recruitment: null,
    };
  },
  mounted() {
    this.blink_interval = setInterval(this.blinker, 1000);
  },
  beforeDestroy() {
    clearInterval(this.blink_interval);
  },
  methods: {
    // async test() {
    //   var test = firebase.functions().httpsCallable("test");
    //   const res = await test({
    //     rec: this.recruitments[0],
    //   });
    //   console.log(res.data);
    // },
    blinker() {
      this.blink = !this.blink;
    },
    deleteRec(item) {
      const vm = this;
      if (confirm("Are you sure that you want to delete this recruitment?")) {
        db.collection("recruitments")
          .doc(item.uid)
          .delete()
          .then(function() {
            console.log("Document successfully deleted!");
          })
          .then(function() {
            vm.$store.dispatch("fetchRecruitments");
          })
          .catch(function(error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    getTime(i) {
      return moment(i).format("DD-MM-YYYY");
    },
    getPp(i) {
      let count = 0;
      i.timeslots.forEach((t) => {
        if (t.participant.uid) {
          count = count + 1;
        }
      });
      return count;
    },
    getBatchLength(b) {
      let count = 0;
      b.forEach((batch) => {
        count = count + batch.length;
      });
      return count;
    },
    getTests(tests) {
      let list = [];
      tests.forEach((t) => {
        let x = this.$store.state.tests.find((test) => test.uid === t);
        list.push(x.name);
      });
      return list;
    },
    openBackup(items) {
      items.forEach((pp) => {
        const routeData = this.$router.resolve({
          path: `/participant/${pp}`,
        });
        window.open(routeData.href, "_blank");
      });
    },
  },
};
</script>
