<template>
  <v-container class="pa-10" v-if="!loading_pp">
    <span @click="back()" style="cursor: pointer"><u>Back</u></span>
    <h1>{{ participant.firstName }} {{ participant.lastName }}</h1>
    <v-btn class="mb-4" color="primary" v-if="!edit" @click="edit = true"
      >Edit</v-btn
    >
    <v-btn
      class="mb-4"
      color="primary"
      v-if="edit"
      @click="
        edit = false;
        saveParticipant();
      "
      >Save</v-btn
    >
    <v-btn
      color="primary"
      class="mb-4 ml-4"
      outlined
      @click="deleteParticipant()"
      ><v-icon>mdi-delete</v-icon></v-btn
    >
    <div class="cards">
      <v-card flat class="pa-5 mr-7 mb-7" style="width: 400px">
        <v-card-title class="headline"><b>Personal details</b></v-card-title>
        <v-divider></v-divider>
        <v-text-field
          v-if="edit"
          class="px-4 mt-3"
          label="First name"
          v-model="participant.firstName"
        ></v-text-field>
        <v-text-field
          v-if="edit"
          class="px-4 mt-3"
          label="First name"
          v-model="participant.lastName"
        ></v-text-field>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle>Full name</v-list-item-subtitle>
            <v-list-item-title
              >{{ participant.firstName }}
              {{ participant.lastName }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          v-if="edit"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="participant.birthday"
              label="Birthday date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              class="px-4"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="participant.birthday"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="save"
          ></v-date-picker>
        </v-menu>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle>Birthday</v-list-item-subtitle>
            <v-list-item-title>{{
              getTime(participant.birthday)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-select
          :items="educationItems"
          v-model="participant.education"
          v-if="edit"
          class="px-4"
          label="Education"
        ></v-select>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle>Education</v-list-item-subtitle>
            <v-list-item-title>{{ participant.education }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-select
          :items="sexItems"
          v-model="participant.sex"
          v-if="edit"
          class="px-4"
          label="Sex"
        ></v-select>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle>Sex</v-list-item-subtitle>
            <v-list-item-title>{{ participant.sex }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-text-field
          v-if="edit"
          class="px-4 mt-3"
          label="Phone number"
          v-model="participant.phone"
        ></v-text-field>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle>Phone</v-list-item-subtitle>
            <v-list-item-title>{{ participant.phone }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-text-field
          v-if="edit"
          class="px-4 mt-3"
          label="Address"
          v-model="participant.address"
        ></v-text-field>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle>Address</v-list-item-subtitle>
            <v-list-item-title>{{ participant.address }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>email</v-list-item-subtitle>
            <v-list-item-title>{{ participant.email }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-text-field
          v-if="edit"
          class="px-4 mt-3"
          label="Postal code"
          v-model="participant.postal"
        ></v-text-field>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle>Postal code</v-list-item-subtitle>
            <v-list-item-title>{{ participant.postal }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-text-field
          v-if="edit"
          class="px-4 mt-3"
          label="City"
          v-model="participant.city"
        ></v-text-field>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle>City</v-list-item-subtitle>
            <v-list-item-title>{{ participant.city }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-text-field
          v-if="edit"
          class="px-4 mt-3"
          label="IBAN"
          v-model="participant.iban"
        ></v-text-field>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle>IBAN</v-list-item-subtitle>
            <v-list-item-title>{{ participant.iban }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-card flat class="pa-5 mr-7 mb-7" style="width: 400px">
        <v-card-title class="headline"><b>Participant data</b></v-card-title>
        <v-divider></v-divider>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>Participant ID</v-list-item-subtitle>
            <v-list-item-title>{{ participant.uid }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-text-field
          v-if="edit"
          class="px-4 mt-3"
          label="Exact relation"
          v-model="participant.relation"
          type="number"
        ></v-text-field>
        <v-list-item two-line v-if="!edit">
          <v-list-item-content>
            <v-list-item-subtitle
              >Relation code (Exact Online)</v-list-item-subtitle
            >
            <v-list-item-title>{{ participant.relation }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle
              >Rated ({{ participant.rated }})</v-list-item-subtitle
            >
            <v-rating
              v-model="participant.rating"
              background-color="secondary"
              color="primary"
              large
              half-increments
              readonly
              dense
              length="3"
            ></v-rating>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>Participated</v-list-item-subtitle>
            <v-list-item-title
              >{{ participant.participated }} x</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>No shows</v-list-item-subtitle>
            <v-list-item-title>{{ participant.noShow }} x</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>Last test</v-list-item-subtitle>
            <v-list-item-title>{{
              getTime(participant.lastTest)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-card flat class="pa-5 mr-7 mb-7" style="width: 400px">
        <v-card-title class="headline"><b>Attributes</b></v-card-title>
        <v-divider></v-divider>
        <v-list-item
          two-line
          v-for="(item, index) in participant.attributes"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
            <v-list-item-title>{{ item.input }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-card flat class="pa-5 mr-7 mb-7" style="width: 400px">
        <v-card-title class="headline"><b>Comments</b></v-card-title>
        <v-divider></v-divider>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>Comments</v-list-item-subtitle>
            <p v-for="comment in participant.comments" :key="comment">
              {{ comment }}
            </p>
          </v-list-item-content>
        </v-list-item>

        <v-row align="center" class="px-4">
          <v-col cols="12" md="8" class="column">
            <v-text-field label="comment" v-model="comment"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn block @click="addComment()">Add</v-btn>
          </v-col>
        </v-row>

        <v-divider class="px-4 my-4"></v-divider>

        <v-row align="center" class="px-4">
          <v-col cols="12" md="8" class="column">
            <v-rating
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half"
              hover
              length="3"
              size="25"
              v-model="rating"
            ></v-rating>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn block @click="addRating()">Add</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<style scoped>
h1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}
</style>

<script>
import { db, auth } from "../firebase";
import firebase from "firebase/app";
import moment from "moment";

export default {
  data() {
    return {
      attributes: [],
      comment: "",
      date: null,
      edit: false,
      educationItems: [
        "VMBO/MAVO/LBO",
        "HAVO (HBS/MMS)",
        "VWO",
        "MBO",
        "HBO",
        "WO",
      ],
      loading_pp: true,
      menu: false,
      participant: null,
      rating: 1,
      sexItems: ["Male", "Female"],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    getId() {
      return this.$route.params.id;
    },
    newRating() {
      let times;
      let rate;

      if (this.participant.rated) {
        times = this.participant.rated;
      } else {
        times = 0;
      }

      if (this.participant.rating) {
        rate = this.participant.rating;
      } else {
        rate = 0;
      }

      const newRate = (rate * times + this.rating) / (times + 1);
      console.log(newRate);
      return newRate;
    },
  },
  methods: {
    addComment() {
      var docRef = db.collection("users").doc(this.$route.params.id);
      const vm = this;
      const comment = this.comment;
      docRef.update({
        comments: firebase.firestore.FieldValue.arrayUnion(comment),
      });
      this.fetchData();
    },
    addRating() {
      const vm = this;
      var docRef = db.collection("users").doc(this.$route.params.id);

      return docRef
        .update({
          rating: vm.newRating,
          rated: firebase.firestore.FieldValue.increment(1),
        })
        .then(function() {
          console.log("Document successfully updated!");
          console.log(vm.newRating);
          vm.fetchData();
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    back() {
      this.$router.go(-1);
    },
    deleteParticipant() {
      const vm = this;
      if (confirm("Are you sure that you want to delete this participant?")) {
        db.collection("users")
          .doc(this.$route.params.id)
          .delete()
          .then(function() {
            console.log("Document successfully deleted!");
          })
          .then(function() {
            vm.$store.dispatch("fetchParticipants");
            vm.back();
          })
          .catch(function(error) {
            console.error("Error removing document: ", error);
          });
      } else {
        console.log("pp NOT deleted");
      }
    },
    fetchData() {
      var docRef = db.collection("users").doc(this.$route.params.id);
      const vm = this;
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.participant = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .then(() => {
          vm.loading_pp = false;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });

      this.attributes = [];
      this.comment = "";

      db.collection("users")
        .doc(this.$route.params.id)
        .collection("attributes")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            vm.attributes.push(doc.data());
          });
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    getTime(time) {
      if (time) {
        return moment(time).format("DD-MM-YYYY");
      }
    },
    saveParticipant() {
      var docRef = db.collection("users").doc(this.$route.params.id);
      const participant = this.participant;
      const vm = this;
      return docRef
        .update(participant)
        .then(function() {
          console.log("Document successfully updated!");
          vm.$store.dispatch("fetchParticipants");
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
