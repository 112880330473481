<template>
    <v-container fluid>
        <div class="banner">
            <img src="@/assets/logo2.png" alt="" style="height: 60px">
            <v-spacer></v-spacer>
            <div class="title">
            <h3>Vragenlijst</h3>
            </div>
        </div>
        <div class="content mt-5 mx-6">
            <h1>Vragenlijst</h1>
            <div v-if="answeredAll">
                <p>U heeft alle vragen beantwoord die nu openstaan.</p>
                <p>Wat kun je verwachten?</p>
                <ul>
                    <li>Wij sturen je een uitnodiging per mail wanneer er een onderzoek is dat bij jou aansluit. </li>
                    <li>Bepaal zelf of en wanneer je mee wilt doen aan het onderzoek.</li>
                    <li>Doe mee aan het onderzoek en ontvang een leuke vergoeding!</li>
                </ul>
            </div>
        </div>
        <div class="content mt-5 mx-6" v-if="!answeredAll">
            <Questions @answered="changeAnsweredAll" />
            
        </div>
    </v-container>
</template>

<style scoped>

.banner {
  background-color: #0A0D3D;
  padding: 30px 30px;
  display: flex;
  flex-wrap: wrap;
}

.title {
  color: white;
  text-align: end;
}

</style>

<script>
import { mapState } from 'vuex'
import { db, auth } from '@/firebase'
import Questions from '@/components/Questions.vue'


export default {
    components: {
        Questions
    },
    data() {
        return {
            answeredAll: false,
        }
    },
    computed: {
        ...mapState(['userProfile']),
        
    },
    created() {

    },
    methods: {
        changeAnsweredAll(value) {
            this.answeredAll = value
        },
    }
    
}
</script>
