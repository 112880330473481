<template>
  <v-container class="pa-10">
    <h1>Email to {{ groupLength }} participants</h1>

    <v-card flat class="pa-5 my-5">
      <v-select
        v-model="template"
        :items="templates"
        style="max-width: 200px"
        label="Email template"
      ></v-select>
      <v-textarea
        v-if="!template"
        v-model="text"
        name="email"
        label="Additional text"
        placeholder="We would like to invite you."
      >
      </v-textarea>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-if="template === 'session-invite' || template === 'dentsu-invite'"
            persistent-hint
            hint="Important: enter the full web address including http."
            v-model="url"
            label="Appointlet URL"
            class="mb-5"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-if="template === 'session-invite' || template === 'dentsu-invite'"
            v-model="duration"
            label="Duration in minutes"
            persistent-hint
            hint="Numbers only!"
            class="mb-5"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-if="template === 'session-invite' || template === 'dentsu-invite'"
            v-model="incentive"
            label="Incentive"
            persistent-hint
            hint="Numbers only!"
            class="mb-5"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn class="mr-3" @click="testMail()">Test email</v-btn>
      <v-btn class="success primary--text" @click="send">Send!</v-btn>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="secondary">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style scoped></style>

<script>
import { db, auth } from "../firebase";

export default {
  data() {
    return {
      email: "",
      duration: null,
      incentive: null,
      group: [],
      snackbar: false,
      text: "Data updated!",
      timeout: 3000,
      subject: "",
      text: "",
      template: "session-invite",
      templates: [
        "attributes",
        "session-invite",
        "dentsu-invite",
        "refer_truckers",
      ],
      url: "",
    };
  },
  created() {
    this.group = this.$route.params.group;
  },
  computed: {
    groupLength: function() {
      return this.group.length;
    },
  },
  methods: {
    testMail() {
      const vm = this;
      const userEmail = auth.currentUser.email;
      db.collection("mail")
        .add({
          to: userEmail,
          replyTo: "research@braingineers.com",
          from: "Braingineers <info@braingineers.com>",
          template: {
            name: vm.template,
            data: {
              firstName: vm.group[0].firstName,
              url: vm.url,
              incentive: vm.incentive,
              duration: vm.duration,
            },
          },
        })
        .then(function(docRef) {
          console.log("email sent to " + userEmail);
          vm.snackbarAction("Test email sent to " + userEmail);
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    },
    send() {
      const group = this.group;
      group.forEach((element) => {
        const vm = this;
        db.collection("mail")
          .add({
            to: element.email,
            replyTo: "research@braingineers.com",
            from: "Braingineers <info@braingineers.com>",
            template: {
              name: vm.template,
              data: {
                firstName: element.firstName,
                url: vm.url,
                incentive: vm.incentive,
                duration: vm.duration,
              },
            },
          })
          .then(function(docRef) {
            console.log("email sent to " + element.email);
            vm.snackbarAction("email sent to participants");
            vm.$router.push("/participants");
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });
      });
    },
    snackbarAction(text) {
      this.text = text;
      this.snackbar = true;
    },
  },
};
</script>
