<template>
  <v-container class="pa-10">
    <router-link to="/tests">Back to tests list</router-link>
    <h1>Create a new test</h1>
    <v-btn class="primary--text mt-4" color="accent" @click="addTest"
      >Save</v-btn
    >
    <div class="cards">
      <div class="left">
        <v-card flat class="pa-5 mr-7 mb-7 my-7" style="width: 400px">
          <v-card-title class="headline"><b>Requirements</b></v-card-title>
          <v-divider class="mb-4 mx-4"></v-divider>

          <v-text-field
            v-model="test.name"
            label="Test name"
            class="px-4"
          ></v-text-field>
          <v-select
            v-model="test.client"
            :items="$store.state.companies"
            :loading="$store.state.loading.companies"
            item-text="name"
            return-object
            label="Client"
            class="px-4"
          ></v-select>
          <v-select
            v-model="test.type"
            :items="typeItems"
            label="Test type"
            class="px-4"
          ></v-select>
          <v-text-field
            v-model="test.invoiced"
            label="Invoiced to client (€)"
            class="px-4"
            type="number"
          ></v-text-field>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}
</style>

<script>
import { db, auth } from "../firebase";

export default {
  data() {
    return {
      test: {
        name: "New test",
        participants: [],
        dates: [],
        client: "",
        duration: null,
        criteria: [],
        payment: "",
      },

      typeItems: ["Desktop", "Mobile", "Video"],
    };
  },
  methods: {
    addTest() {
      const vm = this;
      this.test.company = this.$store.state.userProfile.company;
      db.collection("tests")
        .add(vm.test)
        .then((docRef) => {
          return db
            .collection("tests")
            .doc(docRef.id)
            .update({
              uid: docRef.id,
              created_at: new Date(),
            })
            .then(() => {
              console.log("Document successfully updated!");
              vm.$store.dispatch("fetchTests");
              vm.$router.push(`/test/${docRef.id}?new=true`);
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
  },
};
</script>
