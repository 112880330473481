<template>
  <div>
    <v-card flat class="pa-8" style="max-width: 800px">
      <div class="d-flex justify-space-between align-center mb-3">
        <div>
          <h3 class="">Payments &#128181;</h3>
          <p>All payments to participants.</p>
        </div>
        <v-btn
          @click="approve"
          elevation="0"
          color="primary"
          :disabled="selected.length === 0"
          >Approve</v-btn
        >
      </div>
      <v-data-table
        :headers="headers"
        :items="payments"
        sort-by="time"
        :sort-desc="[true]"
        show-select
        item-key="uid"
        v-model="selected"
        selectable-key="selectable"
      >
        <template v-slot:item.time="{ item }">
          {{ formatDate(item.time) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon v-if="item.payment_approved" color="success"
            >mdi-check-circle</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { db, auth } from "../firebase";
import moment, { min } from "moment";
import firebase from "firebase/app";

export default {
  data() {
    return {
      headers: [
        { text: "First name", value: "firstName" },
        { text: "Last name", value: "lastName" },
        { text: "Incentive", value: "incentive" },
        { text: "IBAN", value: "iban" },
        { text: "Time", value: "time" },
        { text: "Relatie", value: "relation" },
        {
          text: "Approved",
          value: "actions",
        },
      ],
      payments: [],
      selected: [],
      snackbar: false,
      text: "Data updated!",
      timeout: 3000,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async approve() {
      const vm = this;

      for (let x in this.selected) {
        let c = this.selected[x];

        const docRef = await db.collection("payments").add({
          firstName: c.firstName,
          lastName: c.lastName,
          tests: c.tests,
          incentive: c.incentive,
          iban: c.iban,
          ppUid: c.uid,
          time: c.time,
          userUid: auth.currentUser.uid,
          relation: Number(c.relation),
          added: new Date(),
        });
        console.log(docRef.id);

        await db
          .collection("consents")
          .doc(c.uid)
          .update({
            payment_approved: true,
          });
      }
      this.selected = [];
      vm.fetchData();
    },
    unapprove() {
      const vm = this;
      this.selected.forEach(async (c) => {
        db.collection("consents")
          .doc(c.uid)
          .update({
            payment_approved: false,
          });
      });
    },
    fetchData() {
      const vm = this;
      this.payments = [];
      db.collection("consents")
        .where("incentive", ">", 0)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            vm.payments.push({
              selectable: !doc.data().payment_approved,
              ...doc.data(),
            });
          });
        });
    },
    formatDate(time) {
      return moment(time.toDate()).format("DD-MM-YYYY");
    },
  },
};
</script>
