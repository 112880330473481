<template>
  <v-container class="pa-10">
    <h1>Participants: {{ filteredAmount }}</h1>
    <v-btn
      @click="compose()"
      class="my-4 success primary--text"
      :disabled="selected.length === 0"
      >Email ({{ filteredAmount }})</v-btn
    >

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="selected.length === 0"
          class="ml-3 primary--text"
          color="success"
          v-bind="attrs"
          v-on="on"
          >Add to recruitment ({{ filteredAmount }})</v-btn
        >
      </template>

      <v-card class="pa-8">
        <v-select
          background-color="grey lighten-4"
          outlined
          label="Recruitment"
          :items="$store.state.recruitments"
          item-text="title"
          return-object
          multiple
          v-model="selectedRecruitment"
        ></v-select>
        <v-btn @click="addToRecruitment">Add</v-btn>
      </v-card></v-menu
    >
    <div class="cards">
      <v-card max-width="300" flat class="pa-8 mr-4">
        <h3 class="mb-5">Filters</h3>
        <v-text-field
          v-model="searchPostal"
          append-icon="mdi-magnify"
          label="Search postal"
          single-line
          hide-details
          color="primary"
          class=" py-2 my-0"
          style="max-width: 400px"
        ></v-text-field>
        <v-select
          :items="sexItems"
          v-model="sex"
          label="Sex"
          clearable
          prepend-icon="mdi-gender-male-female"
        ></v-select>
        <div class="ageRange">
          <v-text-field
            v-model="minAge"
            label="Min. age"
            type="number"
            prepend-icon="mdi-account-switch"
          ></v-text-field>
          <v-text-field
            v-model="maxAge"
            label="Max. age"
            type="number"
            class="ml-5"
          ></v-text-field>
        </div>

        <v-select
          :items="educationItems"
          v-model="education"
          label="Education"
          multiple
          prepend-icon="mdi-school"
        ></v-select>
        <v-select
          :items="ratingItems"
          v-model="rating"
          label="Min. rating"
          clearable
          prepend-icon="mdi-star"
        ></v-select>
        <v-select
          :items="signUpItems"
          v-model="signUp"
          label="Signed up X days ago"
          clearable
          prepend-icon="mdi-pencil"
        ></v-select>
        <!-- <v-select
          :items="$store.state.tests"
          item-text="name"
          item-value="uid"
          v-model="exclusion"
          label="Exclusion"
          prepend-icon="mdi-stop"
          multiple
        ></v-select> -->
        <v-autocomplete
          :items="$store.state.tests"
          item-text="name"
          item-value="uid"
          v-model="exclusion"
          label="Exclusion"
          prepend-icon="mdi-stop"
          multiple
        ></v-autocomplete>

        <v-autocomplete
          multiple
          :items="attributesSorted"
          v-model="attributes"
          label="Attributes"
          item-text="name"
          return-object
          prepend-icon="mdi-format-list-bulleted"
        ></v-autocomplete>
        <div v-for="(item, index) in attributes" :key="index">
          <p class="pt-3">
            <i>{{ item.description }}</i>
          </p>
          <v-select
            v-model="item.required"
            :items="item.answers"
            item-text="answer"
            item-value="answer"
            multiple
            :label="item.name"
            v-if="item.type === 'Multiple choice'"
          ></v-select>

          <v-select
            v-model="item.required"
            :items="scaleItems"
            multiple
            :label="item.name"
            v-if="item.type === 'Scale 1-10'"
          ></v-select>

          <v-select
            v-model="item.required"
            :items="YesNo"
            :label="item.name"
            v-if="item.type === 'Simple'"
          ></v-select>

          <v-text-field
            v-model="item.required"
            :label="item.name"
            v-if="item.type === 'Open text'"
          ></v-text-field>
        </div>
        <v-btn class="mt-3 mr-4" @click="resetFilter()"
          ><v-icon>mdi-reload</v-icon></v-btn
        >
        <v-btn class="primary mt-3" @click="filter()">Filter!</v-btn>
      </v-card>
      <v-card flat class="pa-8">
        <div class="top align-center mb-3">
          <h3 class="">Participants &#128106;</h3>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            color="primary"
            class="px-4 py-0 my-0"
            style="max-width: 400px"
          ></v-text-field>
        </div>

        <v-data-table
          :headers="headers"
          :items="firstLoad ? $store.state.participants : filtered"
          sort-by="name"
          :sort-desc="[true]"
          :search="search"
          :loading="$store.state.loading.participants"
          show-select
          v-model="selected"
          item-key="uid"
        >
          <template v-slot:item.birthday="{ item }">
            <span>
              {{ getTime(item.birthday) }}
            </span>
          </template>

          <template v-slot:item.rating="{ item }">
            <span>
              {{ Math.floor(item.rating) }}
            </span>
          </template>

          <template v-slot:item.approved="{ item }">
            <v-simple-checkbox
              v-model="item.approved"
              disabled
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-5"
                  @click.stop="consent(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-checkbox-marked-circle-outline
                </v-icon>
              </template>
              <span>Consent form</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click.stop="noShow(item.uid)" v-bind="attrs" v-on="on">
                  mdi-eye-off
                </v-icon>
              </template>
              <span>Add no-show to participant</span>
            </v-tooltip>
            <v-btn
              class="ml-4"
              small
              color="primary"
              elevation="0"
              style="text-transform: none"
              @click="participantPage(item)"
              >Open profile</v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-snackbar v-model="snackbar" :timeout="timeout" color="secondary">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}

.ageRange {
  display: flex;
}
.top {
  display: flex;
}
</style>

<script>
import { db, auth } from "../firebase";
import moment, { min } from "moment";
import router from "../router/index";
import firebase from "firebase/app";

export default {
  components: {},
  data() {
    return {
      attributes: [],
      attributesItems: [],
      dialog: false,
      education: [],
      educationItems: [
        "VMBO/MAVO/LBO",
        "HAVO (HBS/MMS)",
        "VWO",
        "MBO",
        "HBO",
        "WO",
      ],
      exclusion: [],
      filtered: [],
      firstLoad: true,
      headers: [
        { text: "First name", value: "firstName" },
        { text: "Last name", value: "lastName" },
        { text: "Birthday", value: "birthday" },
        { text: "Sex", value: "sex" },
        { text: "Education", value: "education" },
        { text: "Rating", value: "rating" },
        { text: "Actions", value: "actions" },
      ],
      minAge: null,
      maxAge: null,
      menu: false,
      participants: [],
      ratingItems: [3, 2, 1],
      rating: null,
      search: "",
      searchPostal: "",
      selected: [],
      selectedRecruitment: [],
      sex: "",
      sexItems: ["Male", "Female"],
      scaleItems: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
      signUpItems: [1, 2, 7, 14, 30, 60],
      signUp: null,
      snackbar: false,
      text: "Data updated!",
      tests: [],
      timeout: 3000,
      YesNo: ["Yes", "No"],
    };
  },
  computed: {
    filteredAmount: function() {
      if (this.selected.length === 0) {
        return this.filtered.length;
      } else {
        return this.selected.length;
      }
    },
    attributesSorted: function() {
      let att = this.$store.state.attributes;
      // sort by name
      att.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      return att;
    },
  },
  methods: {
    addToRecruitment() {
      this.selectedRecruitment.forEach((r) => {
        let difference = this.selected.filter((x) => !r.batch.includes(x.uid));

        if (difference.length > 0) {
          difference.forEach((pp) => {
            db.collection("recruitments")
              .doc(r.uid)
              .update({
                batch: firebase.firestore.FieldValue.arrayUnion(pp.uid),
              });
          });
          db.collection("recruitments")
            .doc(r.uid)
            .update({
              status: "active",
              out_of_pp: false,
            })
            .then(() => {
              var slack = firebase.functions().httpsCallable("slack");
              let d = {
                text: `Recruitment ${r.title} resumed recruitment of participants.`,
              };
              slack({ template: "resuming", data: d }).then((result) => {
                console.log(result.data);
              });
            });
        } else {
          console.log("No additional participants in selection");
        }
        this.selectedRecruitment = [];
        this.menu = false;
        this.selected = [];
        this.$store.dispatch("fetchRecruitments");
      });
    },
    consent(item) {
      console.log(item);
      this.$router.push({
        name: "Consent",
        path: "/consent",
        params: {
          participant: item,
          payment: "Braingineers",
        },
      });
    },
    compose() {
      if (this.selected.length === 0) {
        this.$router.push({
          name: "Email",
          path: "/email",
          params: {
            group: this.filtered,
          },
        });
      } else {
        this.$router.push({
          name: "Email",
          path: "/email",
          params: {
            group: this.selected,
          },
        });
      }
    },
    fetchAttributes() {
      const vm = this;
      db.collection("attributes")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            vm.attributesItems.push(doc.data());
          });
        });

      db.collection("attributes")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            vm.attributesItems.push(doc.data());
          });
        });
    },

    complex(attributes) {
      let filtered = attributes.filter(complexAtt);
      function complexAtt(obj) {
        return obj.type != "Simple";
      }
      return filtered;
    },

    filter() {
      this.firstLoad = false;
      const pp = this.$store.state.participants;
      const sex = this.sex;
      const edu = this.education;
      const rating = this.rating;
      const minimumAge = this.minAge;
      const maximumAge = this.maxAge;
      const att = this.attributes;
      const excl = this.exclusion;
      const signup = this.signUp;
      const vm = this;
      let score = 0;
      let filteredPp;

      if (sex) {
        filteredPp = pp.filter(filterBySex);
      } else {
        filteredPp = pp;
      }
      console.log(filteredPp);

      if (this.searchPostal) {
        filteredPp = filteredPp.filter((pp) => {
          return pp.postal && pp.postal.includes(this.searchPostal);
        });
      }
      console.log(filteredPp.length);

      function filterBySex(obj) {
        if (obj.sex === sex) {
          console.log(true);
          return true;
        } else {
          console.log(false);
          return false;
        }
      }

      if (edu.length > 0) {
        filteredPp = filteredPp.filter(byEducation);
      }
      console.log(filteredPp);

      function byEducation(obj) {
        if (edu.includes(obj.education)) {
          return true;
        } else {
          return false;
        }
      }

      if (excl.length > 0) {
        filteredPp = filteredPp.filter(byExclusion);
      }

      function byExclusion(obj) {
        let list = [];
        let score = 0;

        if (obj.participations) {
          obj.participations.forEach((element) => {
            element.tests.forEach((test) => list.push(test));
          });
        }

        excl.forEach((ex) => {
          if (list.includes(ex)) {
            score++;
          }
        });

        if (score === 0) {
          return true;
        } else {
          return false;
        }
      }

      if (att.length > 0) {
        filteredPp = filteredPp.filter(byAttributes);
      }
      console.log(filteredPp);

      function byAttributes(ppObj) {
        let score = 0;

        att.forEach((attObject) => {
          const attInPp = ppObj.attributes.find(
            (att) => att.uid === attObject.uid
          );

          if (attObject.type === "Multiple choice") {
            if (attInPp) {
              if (Array.isArray(attInPp.input)) {
                let subscore = 0;
                attInPp.input.forEach((answer) => {
                  if (attObject.required.includes(answer)) {
                    subscore++;
                  }
                });
                if (subscore > 0) {
                  score++;
                }
              } else {
                if (attObject.required.includes(attInPp.input)) {
                  score++;
                }
              }
            }
          } else {
            if (attInPp) {
              if (attObject.required.includes(attInPp.input)) {
                score++;
              }
            }
          }
        });

        return score === att.length;
      }

      if (minimumAge) {
        filteredPp = filteredPp.filter(byMinAge);
      }
      console.log(filteredPp);

      function byMinAge(obj) {
        const today = new Date();
        const thisYear = today.getFullYear();

        if (typeof obj.birthday == "undefined") {
          return false;
        } else if (moment().diff(obj.birthday, "years") >= minimumAge) {
          return true;
        } else {
          return false;
        }
      }

      if (maximumAge) {
        filteredPp = filteredPp.filter(byMaxAge);
      }
      console.log(filteredPp);

      function byMaxAge(obj) {
        const today = new Date();
        const thisYear = today.getFullYear();

        if (typeof obj.birthday == "undefined") {
          return false;
        } else if (moment().diff(obj.birthday, "years") <= maximumAge) {
          return true;
        } else {
          return false;
        }
      }

      if (rating) {
        filteredPp = filteredPp.filter(byRating);
      }
      console.log(filteredPp);

      function byRating(obj) {
        if (obj.rating >= rating) {
          return true;
        } else {
          return false;
        }
      }
      function bySignup(obj) {
        if (obj.signed_up) {
          const date1 = obj.signed_up.toDate();
          var date2 = new Date();
          date2.setDate(date2.getDate() - signup);
          if (date1 > date2) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      if (signup) {
        filteredPp = filteredPp.filter(bySignup);
      }
      this.filtered = filteredPp;
    },
    getTime(time) {
      if (time) {
        return moment(time).format("DD-MM-YYYY");
      }
    },
    noShow(item) {
      db.collection("users")
        .doc(item)
        .update({
          noShow: firebase.firestore.FieldValue.increment(1),
        });
      this.snackbarAction("No show added to user");
    },
    participantPage(item) {
      this.$router.push(`/participant/${item.uid}`);
    },
    resetFilter() {
      this.sex = "";
      this.minAge = null;
      this.maxAge = null;
      this.education = [];
      this.rating = null;
      this.attributes = [];
      this.exclusion = [];
      this.filter();
    },
    snackbarAction(text) {
      this.text = text;
      this.snackbar = true;
    },
    sorted(list) {
      let sortedList = list;
      // sort by name
      sortedList.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      return sortedList;
    },
  },
};
</script>
