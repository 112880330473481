<template>
  <v-container class="pa-10">
    <h1>Settings</h1>
    <v-tabs v-model="tab" background-color="transparent" class="mt-4">
      <v-tab>Attributes</v-tab>
      <v-tab>Groups</v-tab>
      <v-tab>Companies</v-tab>
      <v-tab>Defaults</v-tab>
      <v-tab>Incomplete</v-tab>
      <v-tab>Payments</v-tab>
      <v-tab>Emails</v-tab>
    </v-tabs>
    <v-divider class="mb-3"></v-divider>
    <div class="attributes" v-if="tab === 0">
      <v-btn
        color="accent"
        class="my-4 mr-4 primary--text"
        @click="newAttribute()"
        >+ attribute</v-btn
      >
      <v-card class="px-5 pb-3 my-3" flat>
        <v-row class="align-center">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :items="groupItems"
              item-text="name"
              item-value="uid"
              v-model="groupFilter"
              label="Group"
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn @click="filter()">Filter</v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-data-table
        :headers="headers"
        :items="$store.state.attributes"
        :loading="$store.state.loading.attributes"
        sort-by="name"
        :sort-desc="[true]"
        :search="search"
        @click:row="criteriaPage"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon @click.stop="deleteItem(item)" class="mr-5">
            mdi-delete
          </v-icon>
          <v-icon @click.stop="makeActive(item)" class="mr-5">
            mdi-run
          </v-icon>
          <v-icon @click.stop="makeInactive(item)" class="mr-3">
            mdi-replay
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <div class="groups" v-if="tab === 1">
      <v-row class="align-center" style="width: 510px">
        <v-col cols="12" md="6">
          <v-text-field v-model="group" label="Add new group"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn color="accent" class="my-4 primary--text" @click="newGroup()"
            >Add group</v-btn
          >
        </v-col>
      </v-row>
      <v-data-table
        class="my-3"
        :headers="headers2"
        :items="groupItems"
        sort-by="name"
        :sort-desc="[true]"
        :search="search"
        @click:row="criteriaPage"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon @click.stop="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <Companies v-if="tab === 2" />
    <Defaults v-if="tab === 3" />
    <Incomplete v-if="tab === 4" />
    <Payments v-if="tab === 5" />
    <Emails v-if="tab === 6" />
    <v-snackbar v-model="snackbar" :timeout="timeout" color="secondary">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style scoped></style>

<script>
import { db, auth } from "../firebase";
import Vuex from "vuex";
import { mapState } from "vuex";
import Defaults from "@/components/Defaults.vue";
import Incentives from "@/components/Incentives.vue";
import Companies from "@/components/Companies.vue";
import Incomplete from "@/components/Incomplete.vue";
import Payments from "@/components/Payments.vue";
import Emails from "@/components/Emails.vue";
export default {
  components: {
    Defaults,
    Incentives,
    Companies,
    Incomplete,
    Payments,
    Emails,
  },
  data() {
    return {
      dialog: false,
      sex: "",
      sexItems: ["Male", "Female"],
      group: "",
      groupItems: [],
      groupFilter: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status" },
        {
          text: "Delete // Active // Inactive",
          value: "actions",
          sortable: false,
          width: 200,
        },
      ],
      headers2: [{ text: "Name", value: "name" }],
      criteria: [],
      search: "",
      searchGroups: "",
      snackbar: false,
      text: "Data updated!",
      timeout: 3000,
      tab: "",
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    async fetchData() {
      const vm = this;
      this.criteria = [];
      this.groupItems = [];
      const company = await this.getCompany();

      db.collection("groups")
        .where("company", "==", company)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            vm.groupItems.push(doc.data());
          });
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    async filter() {
      const vm = this;
      const company = await this.getCompany();
      const group = this.groupFilter;
      this.criteria = [];

      db.collection("attributes")
        .where("group", "==", group)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            vm.criteria.push(doc.data());
          });
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    getCompany() {
      return new Promise((resolve) => {
        const vm = this;
        db.collection("users")
          .doc(auth.currentUser.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              resolve(doc.data().company);
            } else {
              console.log("No such document!");
            }
          })
          .catch(function(error) {
            console.log("Error getting document:", error);
          });
      });
    },
    makeActive(item) {
      const vm = this;
      db.collection("attributes")
        .doc(item.uid)
        .update({
          status: "active",
        })
        .then(function() {
          console.log("Document successfully updated!");
          vm.fetchData();
          vm.snackbarAction(`${item.name} activated!`);
        });
    },
    makeInactive(item) {
      const vm = this;
      db.collection("attributes")
        .doc(item.uid)
        .update({
          status: "",
        })
        .then(function() {
          console.log("Document successfully updated!");
          vm.fetchData();
          vm.snackbarAction(`${item.name} inactivated!`);
        });
    },
    criteriaPage(item) {
      this.$router.push(`/setting/${item.uid}`);
    },
    async newAttribute() {
      this.$router.push("/setting/attribute");
    },
    async newGroup() {
      const company = await this.getCompany();
      const vm = this;
      db.collection("groups")
        .add({
          name: vm.group,
        })
        .then(function(docRef) {
          console.log("Document written with ID: ", docRef.id);
          const ref = db.collection("groups").doc(docRef.id);
          return ref
            .update({
              uid: docRef.id,
              timestamp: new Date(),
              company: company,
            })
            .then(function() {
              console.log("Document successfully updated!");
            })
            .catch(function(error) {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        })
        .then(() => {
          vm.fetchData();
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    },
    deleteItem(item) {
      const vm = this;
      if (confirm("Are you sure that you want to delete this attribute?")) {
        db.collection("attributes")
          .doc(item.uid)
          .delete()
          .then(function() {
            console.log("Document successfully deleted!");
            vm.fetchData();
            vm.snackbarAction(`${item.name} deleted!`);
          })
          .catch(function(error) {
            console.error("Error removing document: ", error);
          });
      } else {
        console.log("Item NOT deleted");
      }
    },
    getCompany() {
      return new Promise((resolve) => {
        const vm = this;
        db.collection("users")
          .doc(auth.currentUser.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              resolve(doc.data().company);
            } else {
              console.log("No such document!");
            }
          })
          .catch(function(error) {
            console.log("Error getting document:", error);
          });
      });
    },
    snackbarAction(text) {
      this.text = text;
      this.snackbar = true;
    },
  },
};
</script>
