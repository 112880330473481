<template>
    <v-container class="pa-10">
        <router-link to="/settings">Back to settings</router-link>
        <h1>{{ attribute.name }}</h1>
        <div class="cards">
            <div class="left" v-if="(this.$route.params.id == 'group') || (this.typeGroup)">
                <v-card flat class="pa-5 mr-7 mb-7 my-7" style="width: 400px">
                    <v-card-title class="headline"><b>Group details</b></v-card-title>
                    <v-divider class="mb-4 mx-4"></v-divider>
                    
                    <v-text-field
                    v-model="group.name"
                    label="Name"
                    class="px-4"
                    ></v-text-field>
                                        
                </v-card>
                
            </div>

            <div class="left" v-else>
                <v-card flat class="pa-5 mr-7 mb-7 my-7" style="width: 400px">
                    <v-card-title class="headline"><b>Details</b></v-card-title>
                    <v-divider class="mb-4 mx-4"></v-divider>
                    
                    <v-text-field
                    v-model="attribute.name"
                    label="Name"
                    class="px-4"
                    ></v-text-field>
                    <v-textarea
                    v-model="attribute.description"
                    label="Description"
                    class="px-4"
                    ></v-textarea>

                    <v-select
                    :items="typeItems"
                    v-model="attribute.type"
                    label="Type"
                    class="px-4"
                    ></v-select>
                    <v-select
                    :items="groupItems"
                    item-text="name"
                    item-value="uid"
                    v-model="attribute.group"
                    label="Attribute group"
                    class="px-4"
                    ></v-select>
                    <v-select
                    :items="activateItems"
                    v-model="attribute.status"
                    item-text="text"
                    item-value="value"
                    label="Active"
                    class="px-4"
                    ></v-select>
                    
                </v-card>
                
            </div>

            <div class="right" v-if="attribute.type === 'Multiple choice'">
                <v-card flat class="pa-5 mr-7 mb-7 my-7" style="width: 400px">
                    <v-card-title class="headline"><b>Anwer options</b></v-card-title>
                    <v-divider class="mb-4 mx-4"></v-divider>
                    <v-checkbox
                    label="Allow multiple answers"
                    class="mx-4"
                    v-model="attribute.multipleAnswers"
                    ></v-checkbox>

                    <v-row 
                    align="center"
                    v-for="(item, index) in attribute.answers"
                    :key="index"
                    class="px-4"
                    >
                        <v-col
                        cols="12"
                        md="8"
                        class="column"
                        >
                            <v-text-field
                            v-model="item.answer"
                            label="Answer"
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-btn block @click="deleteAnswer(index)">del</v-btn>
                        </v-col>
                    </v-row>

                    <v-btn 
                    color="primary" 
                    class="mx-4"
                    @click="addAnswer()"
                    >add answer</v-btn>
                </v-card>
            </div>

        </div>
        <v-btn v-if="(this.$route.params.id == 'attribute')" color="accent" @click="save()">Save</v-btn>
        <v-btn v-else-if="(this.$route.params.id == 'group')" color="accent" @click="saveGroup()">Save</v-btn>
        <v-btn v-else color="accent" @click="update()">Update</v-btn>
        <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="secondary"
        >
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
    </v-container>
</template>

<style scoped>

.cards {
    display: flex;
    flex-wrap: wrap;
}

.column {
    padding-top: 0;
    padding-bottom: 0;
}
</style>

<script>
import { db, auth } from '../firebase'

export default {
    data() {
        return {
            activateItems: [
                {
                    text: 'Yes',
                    value: 'active'
                },
                {
                    text: 'No',
                    value: ''
                }
            ],
            attribute: {},
            group: {},
            typeGroup: false,
            complex: false,
            snackbar: false,
            text: 'Data updated!',
            timeout: 3000,
            menu: false,
            typeItems: ['Multiple choice','Open text','Scale 1-10','Simple'],
            groupItems: [],
            complexItems: [true,false],
            answers: [
                {
                    answer: ''
                }
            ],
        }
    },
    created() {
      this.fetchData()
    },
    computed: {
        getId() {
            return this.$route.params.id
        },
    },
    methods: {
        async fetchData() {
            const vm = this;
            this.attribute = {
                answers: [
                    {
                        answer: ''
                    }
                ],
            };
            this.groupItems = [];
            const id = this.getId
            const company = await this.getCompany()

            var docRef = db.collection("attributes").doc(id);

            docRef.get().then(function(doc) {
                if (doc.exists) {
                    vm.attribute = doc.data()
                } else {
                    var docRef = db.collection("groups").doc(id);

                    docRef.get().then(function(doc) {
                        if (doc.exists) {
                            vm.group = doc.data()
                            vm.typeGroup = true
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                    }).catch(function(error) {
                        console.log("Error getting document:", error);
                    });

                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });

            db.collection("groups").where("company", "==", company)
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.groupItems.push(doc.data())
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });


        },
        async save() {
            const vm = this
            const company = await this.getCompany()
            const group = this.attribute.group
            const attribute = this.attribute
            
            db.collection("attributes").add(attribute)
            .then(function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                const ref = db.collection("attributes").doc(docRef.id)
                return ref.update({
                    uid: docRef.id,
                    company: company,
                    timestamp: new Date(),
                })
                /*.then(function() {
                    if (vm.attribute.type == 'Multiple choice') {
                        return ref.update({
                            answers: vm.answers
                        })
                        console.log('answers added')
                    } else {
                        console.log('no answers added')
                    }
                })*/
                .then(function() {
                    console.log("Document successfully updated!");
                })
                .catch(function(error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
                
            })
            .then(function() {
                vm.snackbar = true
                vm.$router.push('/settings')
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });

            
            
        },
        async saveGroup() {
            const vm = this
            const company = await this.getCompany()
            const group = this.group

            db.collection("groups").add(group)
            .then(function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                const ref = db.collection("groups").doc(docRef.id)
                return ref.update({
                    uid: docRef.id,
                    company: company,
                    timestamp: new Date()
                })
                .then(function() {
                    console.log("Document successfully updated!");
                })
                .catch(function(error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
                
            })
            .then(function() {
                vm.snackbar = true
                vm.$router.push('/settings')
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
            
        },
        async update() {
            const vm = this
            const company = this.getCompany()
            var docRef = db.collection("attributes").doc(this.getId);
            console.log(this.complex)

            return docRef.update(vm.attribute)
            .then(function() {
                if (vm.attribute.complex) {
                    return docRef.update({
                        answers: vm.answers
                    })
                    console.log('answers added')
                } else {
                    console.log('no answers added')
                }
            })
            .then(function() {
                console.log("Document successfully updated!");
                vm.snackbar = true
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
                var groupRef = db.collection("groups").doc(vm.getId);

                // Set the "capital" field of the city 'DC'
                return groupRef.update({
                    name: vm.group.name
                })
                .then(function() {
                    console.log("Document successfully updated!");
                    vm.snackbar = true
                })
                .catch(function(error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });

            });

        },
        getCompany() {
            return new Promise(resolve => {
                const vm = this
                db.collection('users').doc(auth.currentUser.uid)
                .get().then(function(doc) {
                    if (doc.exists) {
                    resolve(doc.data().company)
                    } else {
                        console.log("No such document!");
                    }
                })
                .catch(function(error) {
                    console.log("Error getting document:", error);
                });
            })
        },
        addAnswer() {
            this.attribute.answers.push({})
        },
        deleteAnswer(index) {
            this.attribute.answers.splice(index,1)
        },
    }
}
</script>