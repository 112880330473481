<template>
  <v-card flat class="pa-5" style="width: 800px">
    <v-card-title class="headline primary--text"
      ><b>Today's participants</b></v-card-title
    >
    <v-divider class="mb-4 mx-4"></v-divider>
    <v-data-table
      :headers="headers"
      :items="$store.state.todays"
      :loading="$store.state.loading.recruitments"
      sort-by="start"
      :sort-desc="[false]"
      class="mx-4"
      item-key="uid"
    >
      <template v-slot:item.start="{ item }">
        {{ getTime(item.start) }}
      </template>

      <template v-slot:item.tests="{ item }">
        <p class="mb-0" v-for="(i, index) in item.tests" :key="index">
          {{ i.name }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex" v-if="!item.accepted">
          <v-icon class="mr-5" @click.stop="consent(item)">
            mdi-checkbox-marked-circle-outline
          </v-icon>
          <v-icon @click.stop="noShow(item.uid)">
            mdi-eye-off
          </v-icon>
          <v-btn
            class="ml-6"
            small
            elevation="0"
            color="primary"
            @click="participantPage(item)"
            >Profile</v-btn
          >
        </div>
        <div class="d-flex" v-else>
          <v-icon color="success" class="primary--text"
            >mdi-check-circle</v-icon
          >
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { db, auth } from "../firebase";
import moment from "moment";
import firebase from "firebase/app";

export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Start time", value: "start" },
        { text: "Lab", value: "lab" },
        { text: "Tests", value: "tests" },
        { text: "", value: "actions", sortable: false },
      ],
      loading: true,
      users: [],
    };
  },
  methods: {
    consent(item) {
      console.log(item);
      this.$router.push({
        name: "Consent",
        path: "/consent",
        params: {
          participant: item,
        },
      });
    },
    getTime(v) {
      return moment(v).format("HH:mm");
    },
    noShow(item) {
      db.collection("users")
        .doc(item)
        .update({
          noShow: firebase.firestore.FieldValue.increment(1),
        });
    },
    participantPage(item) {
      this.$router.push(`/participant/${item.uid}`);
    },
  },
};
</script>
