<template>
    <div>
        <p>Om een goed overzicht te krijgen van de onderzoeken waar je voor in aanmerking komt, verzoeken we 
            je om enkele vragen te beantwoorden. 
            Zo vergroot je je kans om deel te kunnen nemen aan een onderzoek.</p> 
        <v-card
        flat
        class="pa-5 mb-5"
        v-if="personalInfo"
        >
        <p><b>Persoonlijke info</b></p>
            <v-row>
            <v-col class="py-0">
                <v-text-field
                v-model="participant.firstName"
                label="Voornaam"
                required
                ></v-text-field>  
            </v-col>
            <v-col class="py-0">
                <v-text-field
                v-model="participant.lastName"
                label="Achternaam"
                required
                ></v-text-field>
            </v-col>   
            </v-row>
            <v-row>
            <v-col class="py-0">
                <v-select
                v-model="participant.sex"
                :items="sexItems"
                label="Geslacht"
                item-value="value"
                item-text="text"
                ></v-select>
            </v-col>
            <v-col class="py-0">
                <v-text-field
                v-model="participant.phone"
                label="Telefoonnummer"
                ></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col class="py-0">
                <v-select
                label="Opleidingsniveau"
                v-model="participant.education"
                :items="educationItems"
                ></v-select>
            </v-col>
            <v-col class="py-0">
                <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="participant.birthday"
                    label="Geboortedag"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="participant.birthday"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="saveDate"
                ></v-date-picker>
                </v-menu>
            </v-col>
            </v-row>
        </v-card>
        <v-card
        class="px-4 mb-2"
        v-for="(question, index) in openAttributes"
        :key="index"
        flat
        >
        <v-row align="center" justify="center">
        <v-col
        cols="12"
        >
            <b>{{ question.name }} </b><br>
            {{question.description }}

            <v-select
            v-if="question.type === 'Simple'"
            :items="yesno"
            item-text="text"
            item-value="value"
            label="Ja / Nee"
            v-model="question.input"
            ></v-select>
            <v-select
            v-if="question.type === 'Scale 1-10'"
            :items="scale"
            label="1 = Helemaal oneens / 10 = Helemaal eens"
            v-model="question.input"
            ></v-select>
            <v-select
            v-if="question.type === 'Multiple choice' && !question.multipleAnswers"
            :items="question.answers"
            item-text="answer"
            item-value="answer"
            label="Antwoord"
            v-model="question.input"
            ></v-select>
            <v-select
            v-if="question.type === 'Multiple choice' && question.multipleAnswers"
            :items="question.answers"
            item-text="answer"
            item-value="answer"
            label="Antwoord"
            v-model="question.input"
            multiple
            ></v-select>
            <v-text-field
            v-model="question.input"
            label="Antwoord"
            v-if="question.type === 'Open text'"
            ></v-text-field>
        </v-col>
        </v-row>
        </v-card>
        <div v-if="this.openAttributes.length > 0">
            <p v-if="!answeredAll">Vul alle vragen in om verder te gaan.</p>
            <v-btn :disabled="!answeredAll" color="success" class="primary--text" @click="save">Save!</v-btn>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex'
import { db, auth } from '@/firebase'
import firebase from 'firebase/app'

export default {
    components: {
    },
    data() {
        return {
            allAttributes: [],
            educationItems: ['VMBO/MAVO/LBO','HAVO (HBS/MMS)','VWO','MBO','HBO','WO'],
            date: null,
            openAttributes: [],
            ppAttributes: [],
            participant: {},
            personalInfo: false,
            scale: [10,9,8,7,6,5,4,3,2,1],
            sexItems: [{
                value: 'Male',
                text: 'Man'
                },
                {
                value: 'Female',
                text: 'Vrouw'
            }],
            yesno: [
                {
                    value: 'Yes',
                    text: 'Ja'
                },
                {
                    value: 'No',
                    text: 'Nee'
                }
            ],
            menu: false,
            valid: false,
        }
    },
    computed: {
        ...mapState(['userProfile']),
        answeredAll: function() {
            let score = 0
            let personal = null

            this.openAttributes.forEach((att) => {
                if (att.input) {
                    score++
                }
            })
            const att = (score == this.openAttributes.length)
            
            
            if (!this.participant.firstName || !this.participant.lastName || !this.participant.sex || !this.participant.phone || !this.participant.education || !this.participant.birthday) {
                personal = false
            } else {
                personal = true
            }
            const result = (personal && att)
            console.log(result)
            return result
        },
    },
    created() {
        this.fetchData()
    },
    watch: {
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
    },
    methods: {
        fetchData() {
            const vm = this
            db.collection("attributes").where("status", "==", "active")
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.allAttributes.push(doc.data())
                });
            })
            .then(function() {
                db.collection("sessions").where("status", "==", "Open")
                .get()
                .then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                        function check(item) {
                            function doublecheck(att) {
                                return att.uid === item.uid
                            }
                            if (!vm.allAttributes.find(doublecheck)) {
                                vm.allAttributes.push(item)
                            }
                        }
                        doc.data().attributes.forEach(check)
                    });
                })
                .then(() => {
                    vm.getParticipantAtt()
                })
                .catch(function(error) {
                    console.log("Error getting sessions: ", error);
                });
            })
            .catch(function(error) {
                console.log("Error getting attributes: ", error);
            });
        },
        getParticipantAtt() {
            const vm = this
            db.collection("users").doc(auth.currentUser.uid)
            .get().then(function(doc) {

                if (doc.exists) {
                    vm.participant = doc.data();
                    if (!doc.data().firstName || !doc.data().lastName || !doc.data().sex || !doc.data().phone || !doc.data().education || !doc.data().birthday) {
                        vm.personalInfo = true
                    }
                    doc.data().attributes.forEach(add) 
                    function add(item) {
                        vm.ppAttributes.push(item)
                    }
                } else {
                    console.log("No such document!");
                }
            })
            .then(() => {
  

                function answered(obj) {

                    if (vm.ppAttributes.find((att) => {
                        return att.uid === obj.uid
                    })) {
                        return false
                    } else {
                        return true
                    }
                }

                const filtered = this.allAttributes.filter(answered)

                this.openAttributes = filtered
                
            })
            .then(function() {
                if (vm.openAttributes.length > 0) {
                    vm.$emit('answered',false)
                } else {
                    vm.$emit('answered', true)
                }
            })
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
        },
        save() {
            this.updateProfile()
            this.openAttributes.forEach(element => pushElement(element))
            function pushElement(element) {
                console.log('adding element: ' + element)
                var userRef = db.collection("users").doc(auth.currentUser.uid);
                userRef.update({
                    attributes: firebase.firestore.FieldValue.arrayUnion(element)
                })
                .then(function() {
                console.log("Document successfully written!");
                })
                .catch(function(error) {
                    console.error("Error writing document: ", error);
                });
            }
            this.$emit('answered', true)
            this.openAttributes = []
        },
        saveDate (date) {
            this.$refs.menu.save(date)
        },
        updateProfile() {
            const vm = this
            var userRef = db.collection("users").doc(auth.currentUser.uid);

            return userRef.update({
                firstName: vm.participant.firstName,
                lastName: vm.participant.lastName,
                sex: vm.participant.sex,
                phone: vm.participant.phone,
                education: vm.participant.education,
                birthday: vm.participant.birthday
            })
            .then(function() {
                console.log("Document successfully updated!");
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });

        }
    }
    
}
</script>
