<template>
  <div>
    <v-card flat class="pa-8" style="max-width: 1000px">
      <div class="top align-center mb-3">
        <h3 class="">Emails &#128231;</h3>
        <p>All emails to participants.</p>
        <v-spacer></v-spacer>
      </div>
      <v-data-table
        :headers="headers"
        :items="emails"
        sort-by="time"
        :sort-desc="[true]"
      >
        <template v-slot:item.time="{ item }">
          {{ formatDate(item.time) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { db, auth } from "../firebase";
import moment, { min } from "moment";
import firebase from "firebase/app";

export default {
  data() {
    return {
      emails: [],
      headers: [
        { text: "To", value: "to" },
        { text: "Type", value: "type" },
        { text: "URL", value: "url" },
        { text: "Time", value: "time", width: 200 },
      ],
      payments: [],
      snackbar: false,
      text: "Data updated!",
      timeout: 3000,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const vm = this;
      db.collection("mail")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            if (
              doc.data().delivery &&
              doc.data().delivery.state === "SUCCESS"
            ) {
              let url = "";
              if (
                doc.data().template &&
                doc.data().template.data &&
                doc.data().template.data.url
              ) {
                url = doc.data().template.data.url;
              }
              let name = "";
              if (doc.data().template && doc.data().template.name) {
                name = doc.data().template.name;
              }
              vm.emails.push({
                time: doc.data().delivery.endTime,
                to: doc.data().to,
                type: name,
                url: url,
              });
            }
          });
        });
    },
    formatDate(time) {
      return moment(time.toDate()).format("DD-MM-YYYY");
    },
  },
};
</script>
