<template>
  <v-app>
    <Appbar v-if="!$route.meta.noNav && (userProfile.role == 'admin')"/>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer padless v-if="userProfile.role == 'participant'">
      <v-col
        class="text-center py-4"
        cols="12"
      >
        <span style="cursor: pointer" @click="logout"><u>Log out</u></span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<style scoped>

* {
  background-color: #EBEBEF;
  padding: 0;
  margin: 0;
  color: #0A0D3D;
}
</style>

<script>
import Appbar from '@/components/Appbar'
import router from './router/index';
import Vuex from 'vuex'
import { mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
  name: 'App',

  components: {
    Appbar
  },
  computed: {
    ...mapState(['userProfile']),
  },
  data() {
    return {
      role: ''
    }
  },
  methods: {
    logout() {
        this.$store.dispatch('logout', (auth.currentUser.uid))
    },
  }
};
</script>
