<template>
  <v-app-bar app flat color="primary white--text" dark height="75">
    <img
      src="@/assets/icon.png"
      alt="Logo Braingineers"
      style="max-width: 40px"
    />
    <v-toolbar-title class="title">Panel</v-toolbar-title>
    <v-tabs v-model="tab">
      <v-tab @click="goTo('/')">Home</v-tab>
      <v-tab @click="goTo('/participants')">Participants</v-tab>
      <v-tab @click="goTo('/tests')">Tests</v-tab>
      <v-tab @click="goTo('/recruitments')">Recruitments</v-tab>
      <v-tab @click="goTo('/calendar-participants')">Calendar</v-tab>
      <!--
            <v-tab @click="goTo('/sessions')">Sessions</v-tab>
            -->
      <v-tab @click="goTo('/settings')">Settings</v-tab>
    </v-tabs>
    <v-spacer></v-spacer>

    <v-btn color="white" outlined @click="logout">Logout</v-btn>
  </v-app-bar>
</template>

<style scoped>
.title {
  margin: 0 30px;
  width: 150px;
}
</style>

<script>
import router from "../router/index";
import Vuex from "vuex";
import { mapState } from "vuex";
import { db, auth } from "../firebase";

export default {
  name: "Appbar",
  data() {
    return {
      tab: "",
      menu: false,
    };
  },
  watch: {
    // whenever question changes, this function will run
    tab: function() {
      if (router.currentRoute.path.startsWith("/participant")) {
        this.tab = 1;
      } else if (router.currentRoute.path.startsWith("/test")) {
        this.tab = 2;
      } else if (router.currentRoute.path.startsWith("/recruitments")) {
        this.tab = 3;
      } else if (
        router.currentRoute.path.startsWith("/calendar-participants")
      ) {
        this.tab = 4;
      } else if (router.currentRoute.path.startsWith("/setting")) {
        this.tab = 5;
      } else if (router.currentRoute.path.startsWith("/consent")) {
        this.tab = 0;
      }
    },
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    goTo(url) {
      router.replace(url);
    },
    logout() {
      this.$store.dispatch("logout", auth.currentUser.uid);
    },
  },
};
</script>
