<template>
  <v-dialog v-model="drawer" width="500" persistent>
    <v-card class="">
      <div
        class="d-flex primary white--text justify-space-between align-center px-8 py-4"
      >
        <h2>{{ event.name }}</h2>
        <v-btn icon @click="$emit('close')"
          ><v-icon color="white">mdi-close</v-icon></v-btn
        >
      </div>
      <div class="information pa-4">
        <v-btn color="error" elevation="0" @click="deleteEvent">delete</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { db, auth } from "../firebase";

export default {
  props: ["drawer", "event"],
  methods: {
    deleteEvent() {
      db.collection("calendar")
        .doc(this.event.uid)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          this.$store.dispatch("fetchCalendar");
          this.$emit("close");
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
  },
};
</script>
