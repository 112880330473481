<template>
    <v-container class="pa-10">
        <h1>Bedankt voor het inschrijven!</h1>
        <ol>
            <li>Je ontvangt binnen enkele minuten een e-mail waarmee je jouw inschrijving kunt bevestigen. 
                <b>Let op! Check voor de zekerheid ook je spambox. </b>
            </li>
            <li>Klik na de bevestiging op <b>'Doorgaan'</b> om je inschrijving te voltooien. We hebben dan enkele vragen voor je zodat we
                je beter kunnen uitnodigen voor onderzoeken.
            </li>
        </ol>
        <!--
            <v-btn class="primary white--text" @click="sendEmail">Stuur email opnieuw</v-btn>
        -->
    </v-container>    
</template>

<style scoped>

li {
    margin: 20px 0;
}
</style>

<script>
import Vuex from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            emailSend: false,
        }
    },
    created() {
        this.sendEmail()
        this.logout()
    },
    methods: {
        async logout() {
            await auth.signOut()
            this.$store.commit('setUserProfile', {})
        },
        sendEmail() {
            const vm = this
            console.log('trying to send email')
            var actionCodeSettings = {
            url: 'https://panel.braingineers.com/',
            /*
            iOS: {
                bundleId: 'com.example.ios'
            },
            android: {
                packageName: 'com.example.android',
                installApp: true,
                minimumVersion: '12'
            },
            handleCodeInApp: true,
            // When multiple custom dynamic link domains are defined, specify which
            // one to use.
            dynamicLinkDomain: "example.page.link"
            */
            };
            auth.currentUser.sendEmailVerification(actionCodeSettings)
            .then(function() {
                console.log('email sent!')
                vm.emailSend = true
            })
            .catch(function(error) {
                console.log(error)
            });
        }
    }
}
</script>
