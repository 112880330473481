<template>
    <v-container class="pa-10">
      <h1>Sessions</h1>
      <v-btn color="accent" class="my-4 primary--text" @click="newSession()">+ session</v-btn>
      <v-card
      class="px-5 pb-3 my-3"
      flat
      >
      <v-row>
        <v-col
        cols="12"
        md="4"
        >
          <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="primary"
          ></v-text-field>
        </v-col>
      </v-row>
      </v-card>

      <v-data-table
        :headers="headers"
        :items="sessions"
        sort-by="name"
        :sort-desc="[true]"
        :search="search"
        @click:row="sessionPage"
      >
        <template v-slot:item.tests="{ item }">
          <span>
            {{ getTestNames(item.tests) }}
          </span>
        </template>
        
        <template v-slot:item.approved="{ item }">
          <v-simple-checkbox
            v-model="item.approved"
            disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
          @click="getURL(item.id)"
          class="mr-5"
          >
            mdi-eye
          </v-icon>
          <v-icon
          @click="deleteItem(item)"
          class="mr-5"
          v-if="!item.approved"
          >
            mdi-delete
          </v-icon>
          <v-icon
          @click="approve(item.id)"
          class="mr-5"
          v-if="!item.approved && userProfile.boss"
          >
            mdi-check
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
</template>

<style scoped>

</style>

<script>
import { db, auth } from '../firebase'
import Vuex from 'vuex'
import { mapState } from 'vuex'

export default {
    data() {
        return {
          counter: null,
          dialog: false,
          sex: '',
          sexItems: ['Male','Female'],
          headers: [
            {text: 'Name', value: 'name',},
            {text: 'Tests', value: 'tests'},
            {text: 'Participants', value: "participants.length",},
          ],
          sessions: [],
          search: '',
          tests: [],
        }
    },
    created() {
      this.fetchData()
    },
    computed: {
        ...mapState(['userProfile']),
    },
    methods: {
        async fetchData() {
            const vm = this;
            this.sessions = [];
            const company = await this.getCompany()

            db.collection("sessions")
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.sessions.push(doc.data())
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });

            db.collection("tests").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.tests.push(doc.data())
                });
            });

        },
        getCompany() {
            return new Promise(resolve => {
                const vm = this
                db.collection('users').doc(auth.currentUser.uid)
                .get().then(function(doc) {
                    if (doc.exists) {
                    resolve(doc.data().company)
                    } else {
                        console.log("No such document!");
                    }
                })
                .catch(function(error) {
                    console.log("Error getting document:", error);
                });
            })
        },
        getTestNames(list) {
          let names = []
          var i;

          for (i = 0; i < list.length; i++) {
            console.log(list[i].name)
            names.push(list[i].name)
          }
          
          return names
        },
        newSession() {
          this.$router.push('/session/new');
        },
        sessionPage(item){
            this.$router.push(`/session/${item.uid}`)
        },
        async newSession() {
          const vm = this
          const company = await this.getCompany()
          db.collection("sessions").add({
              name: "New Session",
              company: company,
              status: 'Open',
              participants: [],
              dates: [],
              tests: [],
              duration: null,
              attributes: [],
              payment: '',
              exclusion: [],
              minMale: null,
              minFemale: null,
          })
          .then(async function(docRef) {
              console.log("Document written with ID: ", docRef.id);
              
              return await db.collection("sessions").doc(docRef.id).update({
                uid: docRef.id
              })
              .then(function() {
                vm.$router.push(`/session/${docRef.id}`)
              })
              .catch(function(error) {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
              });
          })
          .catch(function(error) {
              console.error("Error adding document: ", error);
          });
        }
    }
}
</script>